import { Grid, Paper } from '@mui/material'
import { tokens } from "../../theme";
import React from 'react'
import { CardContent, Typography, CardActions, Button } from "@mui/material"
import { useTheme } from '@emotion/react';
//import { useContext } from 'react';
import Card from '@mui/material/Card';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2'

export const BoxCard = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //const colorMode = useContext(ColorModeContext);
  const [twitterCount, setTwitterCount] = useState(0);
  const [linkedinCount, setLinkedinCount] = useState(0);
  const [showMessage, setShowMessage] = useState(false);





  // const linkedinTotalPost = () => {
  //   const email = localStorage.getItem('useremail')
  //   console.log("in total post frontend!!!!!!!!!!!!" , email);
  //   fetch(`${process.env.REACT_APP_URL}/totalposts`, {
  //     method: "POST",
  //     headers: { "Content-type": "application/json" },
  //     body: JSON.stringify({ email: email })
  //   })
  //     .then(response => {
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       console.log(response);
  //       return response.json();
  //     })
  //     .then(data => {
  //       const {  linkedinPostCount } = data;
  //       setLinkedinCount(linkedinPostCount);
  //       console.log("updated",linkedinPostCount);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching counts:', error);
  //     });
  // };

  useEffect(() => {
    userNo()
  }, []);

  // useEffect(() => {
  //   linkedinTotalPost()
  // }, []);


  const totalPost = () => {
    const email = localStorage.getItem('useremail')
    fetch(`${process.env.REACT_APP_URL}/totalposts`, {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({ email : email })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const { twitterPostCount, linkedinPostCount } = data;
        setTwitterCount(twitterPostCount);
        setLinkedinCount(linkedinPostCount);
        console.log(twitterPostCount , linkedinPostCount);
      })
      .catch(error => {
        handleError()
      });
  };

  useEffect(() => {
    totalPost();
  }, []);


  
  const handleError = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the window after clicking "OK"
        window.location.reload();
      }
    });
  }


  

  const userNo = () => {
    console.log("Dddddddddddddddd in userNo");
    fetch(`${process.env.REACT_APP_URL}/noofusers`, {
        method: "POST",
        headers: { "Content-type": "application/json" }
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log(data.count);
            const userCount = data.count;
            localStorage.setItem('userCount', userCount)
            // Log the count received from the backend
        })
        .catch(error => {
            console.error('Error fetching count:', error);
        });
}


  return (



    <Grid container marginTop="20px" display="flex" justifyContent="space-evenly" flexDirection="row">
      <Grid item bgcolor={colors.primary[400]} display="flex" justifyContent="space-evenly" margin="auto">
        <Paper elevation={20}>
          <Card style={{ backgroundColor: "#FF6868", width: '12vw', margin: "8px" }} >
            <CardContent  >
              <Typography variant="h5" component="div">
                {localStorage.getItem('userCount')}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Active users on application
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant="body3" color="text.secondary" fullWidth>Click Here</Button>
            </CardActions>
          </Card>
        </Paper>
      </Grid>

      <Grid item bgcolor={colors.primary[400]} margin="auto" display="flex" justifyContent="space-evenly">
        <Paper elevation={20}>
          <Card style={{ backgroundColor: "#ffc107", width: '12vw', margin: "8px" }}>
            <CardContent  >
              <Typography variant="h5" component="div">
                140+
              </Typography>
              <Typography variant="body2" color="text.secondary">
                My Connections on LinkedIn
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant="body3" color="text.secondary" fullWidth>Click Here</Button>
            </CardActions>
          </Card>
        </Paper>
      </Grid>

      <Grid item bgcolor={colors.primary[400]} display="flex" justifyContent="space-evenly" margin="auto">

        <Paper elevation={20}>
          <Card style={{ backgroundColor: "#17a2b8", width: '12vw', margin: "8px" }}>
            <CardContent >
              <Typography variant="h5" component="div">
                {linkedinCount}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                My post on LinkedIn
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant="body3" color="text.secondary" fullWidth>Click Here</Button>
            </CardActions>
          </Card>
        </Paper>
      </Grid>





      <Grid item bgcolor={colors.primary[400]} display="flex" justifyContent="space-evenly" margin="auto">
        <Paper elevation={20}>
          <Card style={{ backgroundColor: "#ADA2FF", width: '12vw', margin: "8px" }} >
            <CardContent  >
              <Typography variant="h5" component="div">
                200+
              </Typography>
              <Typography variant="body2" color="text.secondary">
                My followers on Twitter
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant="body3" color="text.secondary" fullWidth>Click Here</Button>
            </CardActions>
          </Card>
        </Paper>
      </Grid>


      <Grid item bgcolor={colors.primary[400]} display="flex" justifyContent="space-evenly" margin="auto">
        <Paper elevation={20}>
          <Card style={{ backgroundColor: "#28a745", width: '12vw', margin: "8px" }} >
            <CardContent  >
              <Typography variant="h5" component="div">
                {twitterCount}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Tweets on Twitter
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant="body3" color="text.secondary" fullWidth>Click Here</Button>
            </CardActions>
          </Card>
        </Paper>
      </Grid>


      <Grid item bgcolor={colors.primary[400]} display="flex" justifyContent="space-evenly" alignItems=" center" margin="auto">
        <Paper elevation={20}>
          <Card style={{ backgroundColor: "#fd7e14", width: '12vw', margin: "8px" }}>
            <CardContent  >
              <Typography variant="h5" component="div">
                Send AI Message
              </Typography>
              <Typography variant="body2" component="div">
                To
              </Typography>


            </CardContent>
            <CardActions>
              <Button variant="body3" color="text.secondary" fullWidth>LinkedIn</Button>
              <Button variant="body3" color="text.secondary" fullWidth>Twitter</Button>
            </CardActions>
          </Card>
        </Paper>
      </Grid>
    </Grid>

  )

}


export default BoxCard;
