import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { ColorModeContext , tokens} from "../../theme";
import { useTheme } from '@mui/material/styles';
import { useContext } from "react";
import { useEffect , useState} from 'react';
import { TextField } from '@mui/material';
import makeStyles from '@mui/material/styles';
//import SearchBar from "material-ui-search-bar";
import MessageIcon from '@mui/icons-material/Message';
import SendMessage from './SendMessage';
import LinkedInIcon from '@mui/icons-material/LinkedIn';







function createData( id,name, connection, country, city, message) {
  return {
    id,
    name,
    connection,
    country,
    city,
    
  };
}


const rows=  [
  createData(1, 'James Anderson', 300, 'US', 'Hawaii'),
  createData(2,'James Anderson', 305, 'US', 'California'),
  createData(3,'James Anderson', 306, 'US', 'New Jersey'),
  createData(4,'James Anderson', 307, 'US', 'New Mexico'),
  createData(5,'James Anderson', 308, 'US', 'New York'),
  createData(6,'James Anderson', 309, 'US', 'North Carolina'),
  createData(7,'James Anderson', 310, 'US', 'North Carolina'),
  createData(8,'James Anderson', 311, 'US', 'New Jersey'),
  createData(9,'James Anderson', 312, 'US', 'Hawaii'),
  createData(10,'James Anderson', 313, 'US', 'California'),
  createData(11,'James Anderson', 314, 'US', 'New York'),
  createData(12,'James Anderson', 315, 'US', 'Hawaii'),
  
];


// const rows = [
//   createData('James Anderson', 300, 'US', 'Hawaii'),
//   createData('James Anderson', 305, 'US', 'California'),
//   createData('James Anderson', 306, 'US', 'New Jersey'),
//   createData('James Anderson', 307, 'US', 'New Mexico'),
//   createData('James Anderson', 308, 'US', 'New York'),
//   // createData('James Anderson', 309, 'US', 'North Carolina'),
//   // createData('James Anderson', 310, 'US', 'North Carolina'),
//   // createData('James Anderson', 311, 'US', 'New Jersey'),
//   // createData('James Anderson', 312, 'US', 'Hawaii'),
//   // createData('James Anderson', 313, 'US', 'California'),
//   // createData('James Anderson', 314, 'US', 'New York'),
//   // createData('James Anderson', 315, 'US', 'Hawaii'),
// ];


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Sr No',
  },
  {
    id: 'Names',
    numeric: true,
    disablePadding: false,
    label: 'Name',
    //align:'right'
  },
  {
    id: 'COnnections',
    numeric: true,
    disablePadding: false,
    label: 'Connections',
  },
  {
    id: 'Country',
    numeric: true,
    disablePadding: false,
    label: 'Country',
  },
  {
    id: 'State',
    numeric: true,
    disablePadding: false,
    label: 'State',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  return (
    
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        
        <Typography
          sx={{ flex: '1 1 100%' , display:"flex", justifyContent:"left", alignItems:"center" }}
          variant="h6"
          id="tableTitle"
          component="div"
          fontSize="20px"
          fontWeight="100px"
          
        >
         <LinkedInIcon sx={{ fontSize:"25px", mr:2 }} /> 
          Search People On Linkedin
          
        </Typography>
       
      
      )}

      {numSelected > 0 ? (
         <SendMessage/>
        // <Tooltip title="Send Message">
        //   <IconButton>
        //     <MessageIcon />
        //   </IconButton>
        // </Tooltip>
      ) : (
        
        <Paper elevation={10} style={{margin:1 , width:"200px" , height:"40px" , justifyContent:"center" , alignItems:"center" , display:"flex"}}>
         <Typography>
          Select to send Message
         </Typography>
         </Paper>
        
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export const SearchTable = () => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('connection');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );




  const [searched, setSearched] = React.useState('');
  const [defaultRows, setRows] = useState();

  const handleSearch = (event) => {
    setSearched(event.target.value);
  };

//   

// const requestSearch = (searchedVal) => {
//     const filteredRows = rows.filter((row) => {
//       return row.name.toLowerCase().includes(searchedVal.toLowerCase());
//     });
//     setRows(filteredRows);
//   };

const requestSearch = (searchedVal) => {
  if (typeof searchedVal === 'string') {
    const filteredRows = rows.filter((rows) => {
      return rows.name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  }
};


  

//const paperStyle = { padding: 20 , height: "90%" , width:"47%" ,margin:"20px"}
  
  return (
    <Box sx={{ width: '100%'  ,display:"flex", flexDirection:"column" }}>
      <Paper sx={{ width: '100%', mb: 2}} elevation={10} >
    
        <EnhancedTableToolbar numSelected={selected.length} />
        <TextField label="search Here"  variant='filled' sx={{marginLeft : 2 , marginRight: 2 , width: "98%"}}
          //value={searched}
          onChange={(searchedVal) => requestSearch(searchedVal)}
          //onCancelSearch={() => cancelSearch()}
        />
  
        
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.id}
                    </TableCell>
                    <TableCell align="right">{row.name}</TableCell>
                    <TableCell align="right">{row.connection}</TableCell>
                    <TableCell align="right">{row.country}</TableCell>
                    <TableCell align="right">{row.city}</TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}

export default SearchTable;