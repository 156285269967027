// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
html,
body,
#root,
.app,
.content{
  height: 100%;
  width: 100%;
  font-family: 'Source Sans 3', sans-serif;
}

.app{
  display: flex;
  position: relative;
}


::-webkit-scrollbar{
  width: 10px;

}


/*Track*/
::-webkit-scrollbar-track{
  background: #e0e0e0 ;
}



/*Handle*/
::-webkit-scrollbar-thumb{
  background: #888 ;
}


/*Handle on hover*/
::-webkit-scrollbar-thumb:hover{
  background: #555 ;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  z-index: 1000; /* Ensure it's above other content */
}








/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


/* .home-container {
  background-image: url('../Dashboard.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh; 
} */


`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AAGA;;;;;EAKE,YAAY;EACZ,WAAW;EACX,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;;AAGA;EACE,WAAW;;AAEb;;;AAGA,QAAQ;AACR;EACE,oBAAoB;AACtB;;;;AAIA,SAAS;AACT;EACE,iBAAiB;AACnB;;;AAGA,kBAAkB;AAClB;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC,EAAE,iCAAiC;EACvE,aAAa,EAAE,oCAAoC;AACrD;;;;;;;;;AASA;;;;;;;;;;;;GAYG;;;AAGH;;;;;;GAMG","sourcesContent":["\n@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');\n\nhtml,\nbody,\n#root,\n.app,\n.content{\n  height: 100%;\n  width: 100%;\n  font-family: 'Source Sans 3', sans-serif;\n}\n\n.app{\n  display: flex;\n  position: relative;\n}\n\n\n::-webkit-scrollbar{\n  width: 10px;\n\n}\n\n\n/*Track*/\n::-webkit-scrollbar-track{\n  background: #e0e0e0 ;\n}\n\n\n\n/*Handle*/\n::-webkit-scrollbar-thumb{\n  background: #888 ;\n}\n\n\n/*Handle on hover*/\n::-webkit-scrollbar-thumb:hover{\n  background: #555 ;\n}\n\n.backdrop {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */\n  z-index: 1000; /* Ensure it's above other content */\n}\n\n\n\n\n\n\n\n\n/* body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n} */\n\n\n/* .home-container {\n  background-image: url('../Dashboard.png');\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  height: 100vh; \n} */\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
