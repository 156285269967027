import React from 'react'
import { useEffect, useState } from 'react'
import { json, useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import axios from 'axios'
import XIcon from '@mui/icons-material/X';
import { tokens } from '../../../theme'
import { Button, Paper, TextField, Typography, colors } from '@mui/material';
import { Header } from '../Header'
import Avatar from '@mui/material/Avatar';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import PostAddIcon from '@mui/icons-material/PostAdd';
import Swal from 'sweetalert2'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import DummyProfile from '../../../Images/DummyProfile2.jpg'


export const ShareTweet = () => {

  const [postText, setPostText] = useState('');
  const [edited, setEdited] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [uploadImageUrl, setUploadImageUrl] = useState(null)
  const [loading, setLoading] = useState(false);
  const [postConfirmed, setPostConfirmed] = useState(false);
  const [twitterPostId, setTwitterPostId] = useState(null);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [dropdownValue, setDropdownValue] = React.useState('');
  const [twitterProfileList,setTwitterProfileList] = useState([])

  const userName = localStorage.getItem('userName')
  const twitterAccessToken = localStorage.getItem('twitterAccessToken')
  console.log("in start", twitterAccessToken);
  const twitterAccessTokenSecret = localStorage.getItem('twitterAccessTokenSecret')
  console.log("in start", twitterAccessTokenSecret);


  const navigate = useNavigate();


  useEffect(()=>{
    const useremail = localStorage.getItem('useremail')
    console.log("useremail", useremail)

    if (useremail !== null) {
axios.post(`${process.env.REACT_APP_URL}/twitterProfileList`, {useremail}, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*'
        },
      }).then(res => {console.log(res.data);setTwitterProfileList(res.data)}).catch(err => console.log(err));
    }
  },[])


  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    console.log("Uploaded file:", file);
    const path = URL.createObjectURL(file); // Get the path of the file
    console.log('Path:', path);
    setUploadImageUrl(path)
    setUploadedImage(file);
  };

  useEffect(() => {
    const useremail = localStorage.getItem('useremail')
    console.log("useremail", useremail)

    if (useremail === null) {
      Swal.fire({
        title: "Login Required",
        text: "You need to log in to Shadowbox to use this functionality.",
        icon: "warning", // "fail" should be "warning" or "error"
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Login"
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = '/signin'; // Correctly set the window location
        }
      });
    } else if (twitterAccessToken === "undefined" || twitterAccessToken === "null" || twitterAccessToken === null) {
      handleTwitterLogin()
      // console.log("Comment out handle twitter login")
    }
  }, [twitterAccessToken, navigate]);

  const handleFieldError = () => {
    Swal.fire({
      title: "Error",
      text: "Please Enter text or Image",
      icon: "warning",
      showCancelButton: false, // Set to false to hide the cancel button
      showConfirmButton: true, // Set to true to show the confirm (OK) button
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK" // Change the text of the confirm button to "OK"
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("email error");

      }
    });
  }

  const handleError = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the window after clicking "OK"
        window.location.reload();
      }
    });
  }


  const handlePostSuccess = () => {
    try {
      Swal.fire({
        title: "Successful",
        text: "Post Shared Successfully.",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }

      });
    } catch (error) {
      handleError()
    } finally {
      setShowBackdrop(false);
    }
  }


  const handlePostShare = () => {
    //const confirmed = window.confirm('Are you sure you want to post?');
    try {
      setShowBackdrop(true);
      Swal.fire({
        title: "Are you sure?",
        text: "You want to Post",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      }).then((result) => {
        if (result.isConfirmed) {
          setPostConfirmed(true);
          if (!uploadedImage && !postText) {
            console.log("1")
            handleFieldError()

          }
          // else if (!uploadedImage) {
          //   console.log("2")
          //   handleFieldError()
          //   // twit()
          // }
          // if (!postText) {
          //   console.log("3")
          //   handleFieldError()
          //   // twit()
          // }
          else {
            twit()
          }
        }
      });
    } catch (error) {
      handleError()
    } finally {
      setShowBackdrop(false);
    }
  }







  const twit = async () => {
    const twitterAccessToken = localStorage.getItem('twitterAccessToken');
    const twitterAccessTokenSecret = localStorage.getItem('twitterAccessTokenSecret');

    console.log("Access Token:", twitterAccessToken);
    console.log(twitterAccessTokenSecret);

    const formData = new FormData(); // Create a new FormData object

    // Append the Twitter access tokens to the FormData
    formData.append('twitterAccessToken', twitterAccessToken);
    formData.append('twitterAccessTokenSecret', twitterAccessTokenSecret);

    // Append the post text to the FormData
    formData.append('postText', postText);

    // Check if an image is uploaded
    if (uploadedImage) {
      // Append the uploaded image file to the FormData
      formData.append('image', uploadedImage);
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/testt`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to multipart/form-data
        },
      });

      console.log(response.data); // Print the response data

      if (response.data.status === "success") {
        console.log("Post shared successfully!");
        const id = response.data.twitterPostId;
        savePostID(id)
        handlePostSuccess()
        // window.location.reload();
      } else if (response.data.status === "fail") {
        handleError()
      } else {
        handleError()
      }
    } catch (error) {
      console.error('Error:', error);
      handleError()
    }
  };



  const handleFileInputChange = (e) => {
    console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^");
    const file = e.target.files[0]; // Get the first file from the selected files
    if (file) {

      setUploadedImage(file); // Print the path to the console
    }
  };


  const handleTwitterLogin = () => {
    axios.get(`${process.env.REACT_APP_URL}/twitter/auth`)
      .then(response => {
        console.log('27')
        console.log(response.data)
        window.location.href = response.data;
      })
      .catch(error => {
        console.error('Error logging in:', error);
      });
  };


  const savePostID = (id) => {
    const useremail = localStorage.getItem('useremail')
    const profile = localStorage.getItem('twitterProfile')
    fetch(`${process.env.REACT_APP_URL}/savetwitterpostid`,

      {
        method: "POST",
        body: JSON.stringify({
          "email": useremail,
          "id": id,
          'profile':profile,
        }),
        headers: { "Content-type": "application/json" }
      })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result === "True") {
          console.log("Post id saved successfully!");
        } else if (result === "False") {
          handleError()
        } else {
          handleError()
        }

      })
      .catch(error => handleError())
  }


  

  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);
    localStorage.setItem('twitterProfile',event.target.value)
    const selectedProfile = twitterProfileList.find(profile => profile.twitterProfile === event.target.value);

  if (selectedProfile) {
    // Do something with the selected profile
    console.log('Selected Profile:', selectedProfile);
    localStorage.setItem('twitterAccessToken',selectedProfile.twitterAccessToken)
    localStorage.setItem('twitterAccessTokenSecret',selectedProfile.twitterAccessTokenSecret)

  } else {
    console.log('Profile not found in twitterProfileList');
  }
  };

  const handleLogout=()=>{
    localStorage.removeItem('twitterAccessToken');
    localStorage.removeItem('twitterAccessTokenSecret');
    localStorage.removeItem('twitterProfile');
    handleTwitterLogin()
  }

  const paperStyle = { padding: 2, height: "90%", width: "50%", margin: "5px",marginTop:'5px' }

  return (
    <Box sx={{ display: 'flex', minHeight: "100vh",backgroundColor:'whitesmoke' }}>
      <Header />

      <Box width="95%" minHeight="87vh" m="5px" marginTop="80px" display="flex" justifyContent="center" alignItems='center' flexDirection='column'
        sx={{
          // backgroundImage: 'repeating-radial-gradient(circle at 0 0, rgba(107, 70, 255, .59), #FFF9D0 39%, #03AED2)'

        }}>
        <Paper
          elevation={1}
          style={{ padding: 10, height: "80px", width: "50%",minWidth:'300px', marginTop: "30px" }}
          sx={{ borderRadius: "10px", bgcolor: "white", display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}
        >
          <Box
            borderRadius="10px"
            bgcolor="#007F73"
            width="70%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={0}
            mx={1}
          >
            <Typography
              fontSize="25px"
              fontWeight="100px"
              color="white"
              sx={{ display: "flex", alignItems: "center", flexWrap:'wrap' }}
            >
              <XIcon sx={{ fontSize: "28px", mr: 2 }} />
              @{localStorage.getItem('twitterProfile')}
            </Typography>
          </Box>
          <FormControl 
           borderRadius="10px"
        sx={{ mt: 0, width: '20%', mx: 1 }}
      >
        <InputLabel id="dropdown-label" sx={{ bgcolor: "#007F73", color: "white", pl: 1, pr: 1, borderRadius: 1 }}>Profiles</InputLabel>
        <Select
          labelId="dropdown-label"
          id="dropdown"
          value={dropdownValue}
          onChange={handleDropdownChange}
          label="Profiles"
          sx={{  height:'38px',bgcolor: "#007F73", color: "white", '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
        >
          {
            twitterProfileList.map(x =>
              <MenuItem value={x.twitterProfile}>{x.twitterProfile}</MenuItem>
            )
          }
           <MenuItem onClick={()=>handleLogout()}>Log Out</MenuItem>
          
        </Select>
      </FormControl>
        </Paper>

        <Paper elevation={5} style={paperStyle} sx={{ borderRadius: "10px",minWidth:'300px', bgcolor: "white", display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
          <Box borderRadius="10px" bgcolor="#007F73" width="90%" display="flex" justifyContent="center" alignItems="center" mt={1}>
            <Typography fontSize="25px" fontWeight="100px" color="white" sx={{ display: "flex", alignItems: "center" }}>
              <XIcon sx={{ fontSize: "28px", mr: 2 }} />
              Create Your Tweet Here
            </Typography>
          </Box>
          <TextField
            id="standard-multiline-static"
            label="Write you tweet here!!! Never forgot to use Hashtags"
            multiline
            rows={8}
            //defaultValue="Default Value"
            variant="standard"
            border="2px solid black"
            sx={{ width: "90%", m: "15px" }}
            value={postText}
            onChange={(e) => {
              setPostText(e.target.value);
              setEdited(true);
            }}
          />

          <Button sx={{ color: "white", width: "200px", alignItems: "center", bgcolor: "#007F73" }} variant="contained" component="label" startIcon={<PhotoCameraIcon />}>
            Upload Image
            <input hidden accept="image/*" multiple type="file" onChange={handleImageUpload} />
          </Button>
          {uploadImageUrl !== null && (
            <Box sx={{ width: '90%', m: '15px' }}>
              <Typography variant="body1" color="textSecondary">
                {/* {image.name} */}
              </Typography>
              <img src={uploadImageUrl} alt="Preview" style={{ width: '100%', maxHeight: '250px', objectFit: 'contain' }} />
            </Box>
          )}
          {uploadImageUrl === null && (
            <Box sx={{ width: '90%', m: '15px' }}>
              <Typography variant="body1" color="textSecondary">
                {/* {image.name} */}
              </Typography>
              <img src={DummyProfile} alt="Preview" style={{ width: '100%', maxHeight: '250px', objectFit: 'contain' }} />
            </Box>
          )}

          <Box display="flex" justifyContent="center" alignItems="center">
            <Button sx={{ mt: 5, height: "30px", width: "100px", mb: 2 }} color='success' variant="contained" onClick={handlePostShare} startIcon={<PostAddIcon />}>Post</Button>
          </Box>

        </Paper>
      </Box>

    </Box>
  )
}


