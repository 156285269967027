import { ColorModeContext, useMode } from "./theme";
import { Button, CssBaseline, ThemeProvider } from "@mui/material";
import { Route, Router, Routes } from "react-router-dom";
import MainDashboard from "./scenes/dashboard/MainDashboard";
import { BrowserRouter } from "react-router-dom";
import { Connections } from "./scenes/global/Connections";
import { SharePost } from "./scenes/global/SharePost";
import { UserProfile } from "./scenes/global/UserProfile";
import { Password, SetPassword } from "./scenes/global/SetPassword";
import { ForgotPassword } from "./scenes/global/ForgotPassword";
import Loader from "./scenes/global/Loader";
import SignIn from "./scenes/global/SignIn";
import SetForgotPass from "./scenes/global/SetForgotPass";
import { TwitterLoader } from "./scenes/global/Twitter/TwitterLoader";
import { ShareTweet } from "./scenes/global/Twitter/ShareTweet";
import SearchPeople from "./scenes/global/SearchPeople";
import Search from "./scenes/global/Search";
import HomeTopbar from "./components/HomeTopbar";
import { Home } from "./scenes/global/Home";
import Fbloader from "./scenes/global/Facebook/Fbloader";
import Signup from "./scenes/global/Signup";
import FBloginPosting from "./scenes/global/Facebook/FBloginPosting";
import InstaLoginPosting from "./scenes/global/Instagram/InstaLoginPosting";
import { useState,useEffect } from "react";

import MainDashboard2 from "./components/MainDashboard2/MainDashboard2";


function App() {
  const [theme, colorMode] = useMode();




  return (
    // <Button onClick={postShare}>Share</Button>
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content">
            <Routes>
              <Route path="/mainDashboardOld" element={<MainDashboard />} />
              <Route path="/mainDashboard" element={<MainDashboard2 />} />
              <Route path="/connections" element={<Connections />} /> 
              <Route path="/sharepost" element={<SharePost />} />
              <Route path="/profile" element={<UserProfile />} />
              <Route path="/signin" element={<SignIn />}></Route>
              <Route path="/setpassword" element={<SetPassword />}></Route>
              <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
              <Route path="/loader" element={<Loader />}></Route>
              <Route path="/setforgotpassword" element={<SetForgotPass />}></Route>
              <Route path="/twitterloader" element={<TwitterLoader />} />
              <Route path="/sharetweet" element={<ShareTweet/>}/>
              <Route path="/sharefacebook" element={<FBloginPosting/>}/>
              <Route path="/shareinstagram" element={<InstaLoginPosting/>}/>
              <Route path="/search" element={<Search/>}/> 
               <Route path="/" element={<Home/>}/>
               <Route path="/signup" element={<Signup/>}/>
               <Route path="/fbloader" element={<Fbloader/>}/>

            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
