import React from 'react'

import Section1DataCards from './Section1DataCards'
import { Header } from '../../scenes/global/Header'
import Section2Graphs from './Section2Graphs'

const MainDashboard2 = () => {
  return (
    <div style={{backgroundColor:'#F4F6F9', minHeight:'100%'}}>
        <Header />
        <Section1DataCards/>
        <Section2Graphs/>
    </div>
  )
}

export default MainDashboard2