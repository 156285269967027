// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 420px) {
    .hide-below-420 {
      display: none;
    }
    .show-below-420 {
        display: block;
      }
  }

  @media screen and (min-width: 421px) {
    .hide-below-420 {
      display: block;
    }
    .show-below-420 {
        display: none;
      }
  }
  
  `, "",{"version":3,"sources":["webpack://./src/components/MainDashboard2/cardSmall.css"],"names":[],"mappings":"AAAA;IACI;MACE,aAAa;IACf;IACA;QACI,cAAc;MAChB;EACJ;;EAEA;IACE;MACE,cAAc;IAChB;IACA;QACI,aAAa;MACf;EACJ","sourcesContent":["@media screen and (max-width: 420px) {\n    .hide-below-420 {\n      display: none;\n    }\n    .show-below-420 {\n        display: block;\n      }\n  }\n\n  @media screen and (min-width: 421px) {\n    .hide-below-420 {\n      display: block;\n    }\n    .show-below-420 {\n        display: none;\n      }\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
