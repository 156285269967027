// import * as React from 'react';
// import { BarChart } from '@mui/x-charts/BarChart';

// const uData = [3000, 3000, 2000, 2780, 1890, 2390, 3490, 2300, 3500, 3800, 3000, 2000];

// const xLabels = [
//   'Jan',
//   'Feb',
//   'Mar',
//   'Apr',
//   'May',
//   'Jun',
//   'July',
//   'Aug',
//   'sept',
//   'oct',
//   'nov',
//   'dec'
// ];

// export default function SimpleBarChart() {
//   return (
//     <BarChart
//       width={600}
//       height={400}
//       series={[
//         // { data: pData, label: 'pv', id: 'pvId' },
//         { data: uData, label: 'Account reached', id: 'uvId' },
//       ]}
//       xAxis={[{ data: xLabels, scaleType: 'band' }]}
//     />
//   );
// }



import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Typography } from '@mui/material';

const linkedIn = [4000, 3000, 2000, 2780, 1890, 2390, 3490, 1500, 3450, 2900, 2000, 2500];
const twitter = [2400, 1398, 5000, 3908, 4800, 3800, 4300, 2500, 4000, 3500, 3000, 4000];
const xLabels = [
  'Jan',
  'Feb',
  'March',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export default function BarDiagram() {
  return (
    <BarChart
    
      width={600}
      height={400}
      series={[
        { data: linkedIn, label: 'LinkedIn', id: 'linkedInId' },
        { data: twitter, label: 'Twitter', id: 'twitterId' },
      ]}
      xAxis={[{ data: xLabels, scaleType: 'band' }]}
    />
  );
}
