

import MiniDrawer from '../global/MiniDrawer';
import BottomNavi from '../global/Bottomnavi';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';

export const MainDashboard = () => {
  localStorage.getItem('userSub' )
  const photo = localStorage.getItem('userPhoto' )
 
  // localStorage.setItem('useremail' )
  // localStorage.setItem('userName')
  const userSub = localStorage.getItem('userSub' )

  console.log( userSub)





  return (
    <div className="mainDashboard">
      <MiniDrawer />
    </div>
  )
}
  ;


export default MainDashboard;
