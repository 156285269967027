import React, { useState, useEffect } from 'react';
import { Avatar, Box, Button, FilledInput, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, TextField, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'



export const SetPassword = ({ name, email }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate()


    


    const accessToken = localStorage.getItem('accessToken')
    const userInfo = localStorage.getItem('userInfo')
    const useremail = localStorage.getItem('useremail')
    const userName = localStorage.getItem('userName')
    const userPhoto = localStorage.getItem('userPhoto')
    const userSub = localStorage.getItem('userSub')
    const userContact = localStorage.getItem('userContact')
    console.log(userName)

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };


    const handleFieldError = () => {
        Swal.fire({
            title: "Error",
            text: "Please fill all fields",
            icon: "warning",
            showCancelButton: false, // Set to false to hide the cancel button
            showConfirmButton: true, // Set to true to show the confirm (OK) button
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK" // Change the text of the confirm button to "OK"
          }).then((result) => {
            if (result.isConfirmed) {
              console.log("email error");
              
            }
          });
    }

    const handleError = () => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        }).then((result) => {
          if (result.isConfirmed) {
            // Reload the window after clicking "OK"
            window.location.reload();
          }
        });
      }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!password || !confirmPassword) {
            //setError('Please enter password!!!!!!');
            handleFieldError()
        }
        else if (password !== confirmPassword) {
            setError('Passwords do not match');
        } else if (!isValidPassword(password)) {
            setError('Password must contain at least one special character, one digit, one uppercase letter, and one lowercase letter');
        } else {
            console.log(password);
            setError('');
            setPassword('');
            setConfirmPassword('');
            //loginCheck()
            addUserData()
            navigate("/maindashboard")

        }
    };



    const isValidPassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])(?!.*\s).{8,}$/;
        return regex.test(password);
    };


    //Backend API call ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
    const addUserData = () => {
        fetch(`${process.env.REACT_APP_URL}/userinformation`,

            {
                method: "POST",
                body: JSON.stringify({
                    "useremail": useremail,
                    "password": password,
                    "accessToken": accessToken,
                    "name": userName,
                    "picture": userPhoto,
                    "sub": userSub,
                    
                }),
                headers: { "Content-type": "application/json" }
            })
            .then(response => { console.log(response) })
            .catch(error => { handleError()})
    }




    const paperStyle = { height: "80%", width: "30%", borderRadius: 20 };

    return (
        <Box height="100%" width="100%" display="flex" justifyContent="center" alignItems="center" bgcolor="#4CCD99" onSubmit={handleSubmit}>
            <Paper elevation={20} style={paperStyle} sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }} >

                <Box height="45%" width="100%" bgcolor="#007F73" display="flex" justifyContent="center" alignItems="center" borderRadius="20px 20px 0 0" flexDirection="column">

                    <Avatar sx={{ m: "10px", width: 86, height: 86 }} alt="Cindy Baker" src={userPhoto} />
                    <Typography color="white" fontSize="25px" fontWeight="10">
                        Welcome, {userName}
                    </Typography>

                </Box>


                <Box height="55%" width="100%" m="40px" display="flex" justifyContent="center" alignItems="center" flexDirection="column" >
                    <TextField
                        sx={{ m: 1, width: '30ch' }}
                        id="outlined-read-only-input"
                        label="Username"
                        defaultValue={useremail}
                        InputProps={{
                            readOnly: true,
                        }}
                    />

                    {/* <Typography fontFamily="arial" fontWeight="10px">Password should contain at least one special character, one digit, one upper and lower case letter </Typography> */}
                    <FormControl required sx={{ m: 1, width: '30ch' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}

                            onChange={handlePasswordChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>


                    <FormControl required sx={{ m: 1, width: '30ch' }} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-confirm-password"
                            type="password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            label="Confirm Password"
                        />
                    </FormControl>
                    <Typography variant="caption" color="textSecondary" sx={{ m: "20px" }}>
                        Note : Password should contain at least one special character, one digit, one uppercase letter, and one lowercase letter
                    </Typography>
                    {error && <Typography color="error">{error}</Typography>}


                    <Button variant="contained" color='success' sx={{ m: 1, width: '30ch' }} onClick={handleSubmit}>Set Password</Button>
                </Box>
            </Paper>
        </Box>
    );
}

export default SetPassword;