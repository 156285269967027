import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useState } from "react";
import { tokens } from "../../theme";
import { Button, Paper, TextField, Typography, colors } from '@mui/material';
import { Header } from './Header';
import Avatar from '@mui/material/Avatar';
import { useEffect } from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import LinearProgress from '@mui/material/LinearProgress';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import CommentIcon from '@mui/icons-material/Comment';
import SendIcon from '@mui/icons-material/Send';
import Cookies from 'js-cookie';
import RecyclingIcon from '@mui/icons-material/Recycling';
import axios from 'axios'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';



export const SharePost = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [postText, setPostText] = useState('');
  const [edited, setEdited] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [postConfirmed, setPostConfirmed] = useState(false);
  const [dropdownValue, setDropdownValue] = React.useState('');
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [linkdnProfileList,setLinkdnProfileList] = useState([])

  useEffect(()=>{
    const useremail = localStorage.getItem('useremail')
    console.log("useremail", useremail)

    if (useremail !== null) {
axios.post(`${process.env.REACT_APP_URL}/linkdnProfileList`, {useremail}, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*'
        },
      }).then(res => {console.log(res.data);setLinkdnProfileList(res.data)}).catch(err => console.log(err));
    }
  },[])

  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);
    localStorage.setItem('linkdnProfile',event.target.value)
    const selectedProfile = linkdnProfileList.find(profile => profile.LinkdnProfile === event.target.value);

  if (selectedProfile) {
    // Do something with the selected profile
    console.log('Selected Profile:', selectedProfile);
    localStorage.setItem('accessToken',selectedProfile.LinkdnToken)
    localStorage.setItem('userNameLink',selectedProfile.LinkdnProfile)
    localStorage.setItem('userSubLink',selectedProfile.LinkdnSub)



  } else {
    console.log('Profile not found in linkdnProfileList');
  }
  };


  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    console.log("Uploaded file:", file);
    setUploadedImage(file);
  };

  // useEffect(() => {
  //   console.log("Uploaded image:", uploadedImage);
  // }, [uploadedImage]);
  const accessToken = localStorage.getItem('accessToken')
  const userSub = localStorage.getItem('userSubLink')
  console.log(accessToken)

  const navigate = useNavigate();



  useEffect(() => {
    const useremail = localStorage.getItem('useremail')
    console.log("useremail",useremail)

    if (useremail === null) {
      Swal.fire({
        title: "Login Required",
        text: "You need to log in to Shadowbox to use this functionality.",
        icon: "warning", // "fail" should be "warning" or "error"
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Login"
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = '/signin'; // Correctly set the window location
        }
      });
    }
    else if (accessToken === "undefined" || accessToken === "null" || accessToken === null) {
      handleLinkedinLogin()
    }
  }, [accessToken, navigate]);



  const handleLinkedinLogin = () => {
    console.log("login ---------------------118")
    // const linkedinAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77mwnchaglz2x0&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Floader&scope=profile%20email%20openid%20w_member_social`;
    const linkedinAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77mwnchaglz2x0&redirect_uri=https%3A%2F%2Fshadowbox.ai%2Floader&scope=profile%20email%20openid%20w_member_social`;
    window.location.href = linkedinAuthUrl;
  }



  const handleFieldError = () => {
    Swal.fire({
      title: "Error",
      text: "Please Enter text or Image",
      icon: "warning",
      showCancelButton: false, // Set to false to hide the cancel button
      showConfirmButton: true, // Set to true to show the confirm (OK) button
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK" // Change the text of the confirm button to "OK"
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("email error");

      }
    });
  }


  const handleError = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the window after clicking "OK"
        window.location.reload();
      }
    });
  }


  const handleImageError = () => {
    Swal.fire({
      title: "Error",
      text: "Erron in Uploading image",
      icon: "warning",
      showCancelButton: false, // Set to false to hide the cancel button
      showConfirmButton: true, // Set to true to show the confirm (OK) button
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK" // Change the text of the confirm button to "OK"
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the window after clicking "OK"
        window.location.reload();
      }
    });
  }




  const handlePostShare = () => {
    //const confirmed = window.confirm('Are you sure you want to post?');
    try {
      setShowBackdrop(true);
      Swal.fire({
        title: "Are you sure?",
        text: "You want to Post",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      }).then((result) => {
        if (result.isConfirmed) {
          setPostConfirmed(true);
          if (!uploadedImage && !postText) {
            handleFieldError()
          }
          else if (!uploadedImage) {
            shareTextAPI()
          }
          else if (uploadedImage) {
            registerUpload()
          }
        }
      });
    } catch (error) {
      handleError()
    } finally {
      setShowBackdrop(false);
    }
  }


  const handlePostSuccess = () => {
    try {
      Swal.fire({
        title: "Successful",
        text: "Post Shared Successfully.",
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes"
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }

      });
    } catch (error) {
      handleError()
    } finally {
      setShowBackdrop(false);
    }
  }



  // const shareTextAPI = () => {
  //   fetch(`https://api.linkedin.com/v2/ugcPosts?oauth2_access_token=${accessToken}`,
  //     {
  //       method: "POST",
  //       header: {
  //         "X-Restli-Protocol-Version": "2.0.0",

  //       },
  //       body: JSON.stringify({
  //         "author": `urn:li:person:${userSub}`,
  //         "lifecycleState": "PUBLISHED",
  //         "specificContent": {
  //           "com.linkedin.ugc.ShareContent": {
  //             "shareCommentary": {
  //               "text": postText
  //             },
  //             "shareMediaCategory": "NONE"
  //           }
  //         },
  //         "visibility": {
  //           "com.linkedin.ugc.MemberNetworkVisibility": "PUBLIC"
  //         },
  //       }),

  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((json) => {
  //       const id = json.id
  //       console.log(id)
  //       savePostID(id)



  //     })
  //     .catch((error) => handleError());

  // }

  const shareTextAPI = () => {
    fetch(`${process.env.REACT_APP_URL}/linkedin-api`, { // Send request to your backend endpoint
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        accessToken: accessToken, // Pass your LinkedIn access token to the backend
        userSub: userSub, // Pass user sub ID if needed
        postText: postText // Pass the text you want to share
      })
    })
    .then((response) => response.json())
    .then((json) => {
      const id = json.id;
      console.log(id);
      savePostID(id);
    })
    .catch((error) => handleError(error));
  };



  const registerUploadFrontend = () => {

    fetch(`https://api.linkedin.com/v2/assets?action=registerUpload&oauth2_access_token=${accessToken}`,
      {
        method: "POST",
        header: {
          "X-Restli-Protocol-Version": "2.0.0",
          //"Authorization" : "Bearer AQVCC8EkoXrhj2egJPpe9R66yuPQG0f9QAhmJJmc1Ek13dVtuAyd0VtgoFWRcIDAklh6ipp6GlZMfJfHkVzNDv2EcrhaajzpQpXbmajBA_JW0IVLm3_ZN4EYDXnZbq9_8Ik8jpp9bukUYy-doAnOUn_MnjHj233E5dvOu5TMIy6VY6fVouGvjWLW8NB2ka7AwbAzbeaueutThCv-YHRt1fbHAaRQK9a9C25EidsmRV4K-PEdb6FYZD44An2YMU2WiuoICH3SrP_-viLzcFsheRBkrr3Rxi4L2WP55lmOA_L-yW1VgSERoL0_AshA7GUxG8j9tstBpfFXjHt4TCgSimx7SP4ffQ"
        },
        body: JSON.stringify({

          "registerUploadRequest": {
            "recipes": [
              "urn:li:digitalmediaRecipe:feedshare-image"
            ],
            "owner": `urn:li:person:${userSub}`,
            "serviceRelationships": [
              {
                "relationshipType": "OWNER",
                "identifier": "urn:li:userGeneratedContent"
              }
            ]
          }

        }),

      }
    )
      .then((response) => response.json())
      .then(json => {
        console.log(json)
        const uploadUrl = json.value.uploadMechanism["com.linkedin.digitalmedia.uploading.MediaUploadHttpRequest"].uploadUrl;
        localStorage.setItem('uploadUrl', uploadUrl)
        const asset = json.value.asset
        localStorage.setItem('asset', asset)
        console.log("frontend api change afterwaords--",uploadUrl)
        console.log(asset);
        uploadImage()
      })
      .catch(
        (error) => handleError());


  }

  const registerUpload = async() => {
    console.log('userSub')
    try{
    const response = await fetch(`${process.env.REACT_APP_URL}/linkedin-register-upload`, { // Send request to your backend endpoint
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        accessToken: accessToken, // Pass your LinkedIn access token to the backend
        userSub: userSub // Pass user sub ID if needed
      })
    })
    // .then((response) => response.json())
    // .then((json) => {
    //   const uploadUrl = json.value.uploadMechanism["com.linkedin.digitalmedia.uploading.MediaUploadHttpRequest"].uploadUrl;
    //   localStorage.setItem('uploadUrl', uploadUrl);
    //   const asset = json.value.asset;
    //   localStorage.setItem('asset', asset);
    //   console.log(uploadUrl);
    //   console.log(asset);
    // uploadImage();

    if (!response.ok) {
      throw new Error('Error registering upload');
    }
    const json = await response.json();
    const uploadUrl = json.uploadUrl;
    const asset = json.asset;
    const userSubB = json.userSub
    const jsonB = json.json

    localStorage.setItem('uploadUrl', uploadUrl);
    localStorage.setItem('asset', asset);

    console.log(uploadUrl);
    console.log(asset);
    console.log(userSubB)
    console.log(jsonB)

    // Optionally, call the uploadImage function here
    uploadImage();

  } catch (error) {
    console.error('Error:', error);
  }
  };

  const uploadImage = async () => {
    const formData = new FormData();
    formData.append('file', uploadedImage);
  
    const uploadUrl = localStorage.getItem('uploadUrl');
    const accessToken = localStorage.getItem('accessToken')

    const userAccessToken = `oauth2_access_token=${accessToken}`;
      const finalUrlF = `${uploadUrl}&${userAccessToken}`;
    // 
  
    formData.append('uploadUrl', uploadUrl);
    formData.append('accessToken', accessToken);
    formData.append('finalUrlF', finalUrlF);

  
    try {
      const response = await fetch(`${process.env.REACT_APP_URL}/uploadImage`, {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        console.log('Image uploaded successfully!');
        shareImageAPI();
      } else {
        console.error('Failed to upload image');
        handleImageError();
      }
    } catch (error) {
      console.error('Error uploading image frontend', error);
      handleImageError();
    }
  };  



  // const uploadImage = () => {
  //   const formData = new FormData();
  //   formData.append('file', uploadedImage);
  //   console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^")
  //   const uploadUrl = localStorage.getItem('uploadUrl')
  //   const userAccessToken = `oauth2_access_token=${accessToken}`;
  //   const finalUrl = `${uploadUrl}&${userAccessToken}`;
  //   console.log("^^^^^^^^^^^^^6", finalUrl);
  //   try {
  //     fetch(finalUrl, {
  //       method: "POST",
  //       header: {
  //         "content-type": "application/octet-stream",
  //       },

  //       body: formData

  //     })
  //       .then((response) => {
  //         if (response.ok) {
  //           console.log("Image uploaded successfully!");
  //           //return response.json();
  //           shareImageAPI()
  //         }
  //       })
  //   } catch (error) {
  //     handleImageError()
  //   }

  // }


  // const uploadImage = async () => {
  //   const formData = new FormData();
  //   formData.append('file', uploadedImage);
  //   const uploadUrl = localStorage.getItem('uploadUrl')
  //     const userAccessToken = `oauth2_access_token=${accessToken}`;
  //     const finalUrl = `${uploadUrl}&${userAccessToken}`;
  
  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_URL}/linkedin-upload-image`, {
  //       method: "POST",
  //       body: formData,
  //       headers: {
  //         "Content-Type": "application/json"
  //       },
  //       body: JSON.stringify({
  //         accessToken: accessToken,
  //         file: formData,
  //         finalUrl:finalUrl

  //       })
  //     });
  
  //     if (response.ok) {
  //       const data = await response.json();
  //       console.log("Image uploaded successfully!");
  //       shareImageAPI(); // Assuming this function exists to handle further actions after image upload
  //     } else {
  //       console.error("Failed to upload image to LinkedIn");
  //     }
  //   } catch (error) {
  //     console.error("Error uploading image:", error);
  //     handleImageError();
  //   }
  // };

  const shareImageAPI = () => {
    const asset = localStorage.getItem('asset');
    fetch(`${process.env.REACT_APP_URL}/share`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        accessToken,
        userSub,
        postText,
        asset
      })
    })
      .then((response) => response.json())
      .then((json) => {
        const id = json.id;
        console.log(id);
        savePostID(id);
      })
      .catch((error) => handleError());
  }

  // const shareImageAPI = () => {
  //   const asset = localStorage.getItem('asset')
  //   fetch(`https://api.linkedin.com/v2/ugcPosts?oauth2_access_token=${accessToken}`,
  //     {
  //       method: "POST",
  //       header: {
  //         "X-Restli-Protocol-Version": "2.0.0",
  //       },
  //       body: JSON.stringify({

  //         "author": `urn:li:person:${userSub}`,
  //         "lifecycleState": "PUBLISHED",
  //         "specificContent": {
  //           "com.linkedin.ugc.ShareContent": {
  //             "shareCommentary": {
  //               "text": postText
  //             },
  //             "shareMediaCategory": "IMAGE",
  //             "media": [
  //               {
  //                 "status": "READY",
  //                 "description": {
  //                   "text": "Center stage!"
  //                 },
  //                 "media": asset,
  //                 "title": {
  //                   "text": "LinkedIn Talent Connect 2021"
  //                 }
  //               }
  //             ]
  //           }
  //         },
  //         "visibility": {
  //           "com.linkedin.ugc.MemberNetworkVisibility": "PUBLIC"
  //         }

  //       }),

  //     }
  //   )
  //     .then((response) => response.json())
  //     .then((json) => {
  //       const id = json.id
  //       console.log(id)
  //       savePostID(id)
  //     })
  //     .catch((error) => handleError())


  // }


  const savePostID = (id) => {
    const useremail = localStorage.getItem('useremail')
    const profile = localStorage.getItem('userNameLink')
    fetch(`${process.env.REACT_APP_URL}/savelinkedinpostid`,

      {
        method: "POST",
        body: JSON.stringify({
          "email": useremail,
          "id": id,
          'profile':profile,
        }),
        headers: { "Content-type": "application/json" }
      })
      .then(response => response.json())
      .then(result => {
        console.log(result)
        if (result === "True") {
          setLoading(false); // Hide loading indicator
          console.log("Post shared successfully!");
          handlePostSuccess()
        } else if (result === "False") {
          handleError()
        } else {
          handleError()
        }

      })
      .catch(error => { console.log(error) })
  }




  const paperStyle = { padding: 5, height: "90%", width: "47%", margin: "5px", minWidth:'300px', borderRadius:'10px' }
  const userName = localStorage.getItem('userNameLink')

  const handleLogout=()=>{
    console.log('LOgout')
    localStorage.removeItem('userNameLink');
localStorage.removeItem('accessToken');
localStorage.removeItem('userSubLink');
localStorage.removeItem('useremailLink');


// Remove cookies (assuming you have a function to remove cookies)
// handleLinkedinLogin()
  }

  return (
    <Box sx={{ display: 'flex', height:'100%', backgroundColor:'whitesmoke' }}>
      <Header />
      {showBackdrop && (
        <div className="backdrop">
          <LinearProgress color="success" />
        </div>
      )}

      <Box width="100%"  height='100%'     marginTop="80px" display="flex" flexWrap='wrap' justifyContent="space-evenly" flexDirection="row" alignItems='center'
        sx={{
          // backgroundImage: 'repeating-radial-gradient(circle at 0 0, rgba(107, 70, 255, .59), #FFF9D0 39%, #03AED2)'

        }}>

        <Paper elevation={5}  sx={{ paddingLeft: 1, paddingRight: 1, borderRadius:'10px' , width: "40%", display: "flex",minWidth:'300px', justifyContent: "center", bgcolor: "white", flexDirection: 'column', alignItems: 'center' }} >


          <Box borderRadius="10px" bgcolor="#007F73" width="90%" display="flex" justifyContent="center" alignItems="center" mb={2} marginTop='20px' >
            <Typography fontSize="25px" fontWeight="100px" color="white" sx={{ display: "flex", alignItems: "center" }}>
              <LinkedInIcon sx={{ fontSize: "28px", mr: 2 }} />
              Create Your Post Here
            </Typography>
          </Box>
          {/* <Box border="2px solid black"> */}

          <TextField
            id="standard-multiline-static"
            label="Write your post here!!!!!!!!!!"
            // defaultValue='Write your Caption  Here!!!!!'
            multiline
            rows={10}
            fullWidth
            //defaultValue="Default Value"
            variant="standard"
            border="2px solid black"
            sx={{ width: "100%", mb: "10px",fontSize:'large' }}
            value={postText}
            onChange={(e) => {
              setPostText(e.target.value);
              setEdited(true);
            }}
          />
          {/* </Box> */}

          <Button sx={{ color: "white", width: "200px", alignItems: "center", bgcolor: "#007F73" }} variant="contained" component="label" startIcon={<PhotoCameraIcon />}>
            Upload Image
            <input hidden accept="image/*" multiple type="file" onChange={handleImageUpload} />
          </Button>

          <Button
              sx={{
                mt: 4,
                mb: 3,
                height: '30px',
                width: '70%',
                bgcolor:'#007F73',
                color:'white'
                // bgcolor: blinking ? '#007F73' : 'transparent',
                // color: blinking ? 'white' : '#007F73',
                // transition: 'background-color 0.3s, color 0.3s',
              }}
              fullWidth
              variant="contained"
              onClick={handlePostShare}
              startIcon={<PostAddIcon />}
            >
              Share Post
            </Button>






        </Paper>

        <Paper elevation={20} style={paperStyle} sx={{ bgcolor: "white" }}>
          <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center" flexDirection="column" >
            <Box borderRadius="10px" bgcolor="#007F73" width="90%" display="flex" justifyContent="center" alignItems="center">
              <Typography fontSize="25px" fontWeight="100px" color="white" sx={{ display: "flex", alignItems: "center" }}>
                <LinkedInIcon sx={{ fontSize: "28px", mr: 2 }} />
                Demo Post On LinkedIn
              </Typography>
            </Box>
            <Box height="100px"  width="90%" display="flex" justifyContent="flex-start" alignItems="center" bgcolor="white">
              <Avatar sx={{ m: "10px" }} alt="Cindy Baker" src={localStorage.getItem('userPhotoLink')} />
              {/* <Typography sx={{m:"10px"}}> */}
              <TextField
             
                id="standard-read-only-input"
                 value={localStorage.getItem('userNameLink')}
                defaultValue={localStorage.getItem('userNameLink')}
                InputProps={{
                  readOnly: true,
                }}
                variant="standard"
              />
               <FormControl 
           borderRadius="10px"
        sx={{ mt: 0, width: '20%', mx: 1 }}>
               <InputLabel id="dropdown-label" sx={{ bgcolor: "#007F73", color: "white", pl: 1, pr: 1, borderRadius: 1, }}>Profiles</InputLabel>
        <Select
          labelId="dropdown-label"
          id="dropdown"
          value={dropdownValue}
          onChange={handleDropdownChange}
          label="Profiles"
          sx={{  height:'38px',bgcolor: "#007F73", color: "white", '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
        >
          {
            linkdnProfileList.map(x =>
              <MenuItem value={x.LinkdnProfile}>{x.LinkdnProfile}</MenuItem>
            )
          }
          <MenuItem onClick={()=>handleLogout()} >Log Out</MenuItem>
        </Select>
        </FormControl>

              {/* </Typography> */}
            </Box>
            <Box
      paddingTop='10px'
      width="90%"
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Typography
        style={{ marginTop: '10px', overflowWrap: 'break-word' }}
        sx={{ m: "10px" }}
      >
        {postText || (!edited && "Your Post Text")}
      </Typography>
    </Box>
            <Box display="flex" justifyContent="center" alignItems="center" height="60%" width="90%" bgcolor="skyblue">
              <Box id="image" display="flex" justifyContent="center" height="95%" width="97%" bgcolor="white" alignItems="center" >
                {uploadedImage && <img src={URL.createObjectURL(uploadedImage)} alt="Uploaded" style={{ maxWidth: "100%", maxHeight: "100%" }} />}
              </Box>
            </Box>
            <Box width="100%" mt={2} display='flex' justifyContent='space-evenly' alignItems='center' flexDirection='row'>
              <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                <ThumbUpAltIcon color='primary'/>
                <Typography>
                  Like
                </Typography>
              </Box>

              <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                <CommentIcon color='primary'/>
                <Typography>
                  Comment
                </Typography>
              </Box>

              <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                <RecyclingIcon color='primary'/>
                <Typography>
                  Repost
                </Typography>
              </Box>

              <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                <SendIcon color='primary'/>
                <Typography>
                  Share
                </Typography>
              </Box>

            </Box>
                      

          </Box>
        </Paper>
      </Box>

    </Box>
  );
}


export default SharePost;