import { AppBar, Box, Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Paper, Toolbar, Tooltip, Typography } from '@mui/material'
import React from 'react'
import MuiDrawer from '@mui/material/Drawer';
import Topbar from './Topbar'
import MenuIcon from '@mui/icons-material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SearchIcon from '@mui/icons-material/Search';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PersonIcon from '@mui/icons-material/Person';
import MailIcon from '@mui/icons-material/Mail';
import PeopleIcon from '@mui/icons-material/People';
import MuiAppBar from '@mui/material/AppBar';
import BottomNavi from './Bottomnavi';
import Avatar from '@mui/material/Avatar';
import CableOutlinedIcon from '@mui/icons-material/CableOutlined';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import favicon from '../../Images/favicon.ico'
import Swal from 'sweetalert2'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';





export const Header = () => {

    const userName = localStorage.getItem('userName')
    console.log(userName)

    const drawerWidth = 240;
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();

    const [opendrop, setOpendrop] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleError = () => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "This module is under progress",
        }).then((result) => {
          if (result.isConfirmed) {
            // Reload the window after clicking "OK"
            window.location.reload();
          }
        });
      }


    const handleWorkProgress = () => {
        handleError()
    }

    const handleDropdownClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };



    const handleDrawerOpen = () => {
        setOpen(true);
    };


    const handleDrawerClose = () => {
        setOpen(false);
    };


    const openedMixin = (theme) => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });


    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 10px)`, // Increase width here
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(8)} + 10px)`, // Adjust for breakpoints
        },
    });



    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== 'open',
    })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#7AA2E3', // Set background color to white
        ...(open && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }));


    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            ...(open && {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }),
            ...(!open && {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }),
        }),
    );


    // const DrawerHeader = styled('div')(({ theme }) => ({
    //     display: 'flex',
    //     alignItems: 'center',
    //     justifyContent: 'flex-end',
    //     padding: theme.spacing(0, 1),
    //     // necessary for content to be below app bar
    //     ...theme.mixins.toolbar,
    // }));

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const LogoContainer = styled('div')({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    });

    const Logo = styled(Avatar)(({ theme }) => ({
        marginLeft: theme.spacing(1),
        bgcolor: 'secondary.main',
    }));

    const LogoText = styled(Typography)({
        fontSize: '25px',
        marginLeft: '10px',
    });
    return (
        <Box>
            <AppBar position='fixed' open={open}>
                <Toolbar>
                    <IconButton
                        color="white"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box justifyContent="end" width="100%" >
                        <Topbar />
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    {/* <Paper elevation={5} style={{display:"flex" , width:"100%"}}> */}

                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'left',
                            px: 2.5,
                            bgcolor: "skyblue",
                            justifyItems: 'center'
                        }}


                    >
                        <ListItemIcon sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}>

                        </ListItemIcon>
                        <Box display='flex' alignItems='center' justifyContent='space-evenly'>
                            <LogoContainer>
                                
                                <LogoText>DSHG Sonic</LogoText>
                            </LogoContainer>
                            {/* <ListItemText primary="DSHG Sonic" sx={{ opacity: open ? 1 : 0  , fontSize:"10px"}} /> */}
                            <IconButton onClick={handleDrawerClose} >
                                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </Box>
                    </ListItemButton>




                    {/* </Paper> */}





                </DrawerHeader>

                <Divider />
                <List>


                    <Paper elevation={5} style={{ margin: 10 }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                
                            }}


                        >
                            <ListItemIcon sx={{
                                minWidth: 0,
                                mr: open ? 3 : 'auto',
                                justifyContent: 'center',
                            }}>
                                <Avatar sx={{ width: 24, height: 24 }} alt="Cindy Baker" src={localStorage.getItem('userPhoto')} />
                            </ListItemIcon>
                            <ListItemText primary={localStorage.getItem('userName')} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    </Paper>


                    <Tooltip title="Home" placement="right-start">
                        <Paper elevation={5} style={{ margin: 10 }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}

                                href='/mainDashboard'
                            >
                                <ListItemIcon sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}>
                                    <HomeOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Home" sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </Paper>
                    </Tooltip>

                    <Tooltip title="Profile" placement="right-start">
                        <Paper elevation={5} style={{ margin: 10 }}>
                            <ListItemButton href='/profile'
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}>
                                    <PersonIcon />
                                </ListItemIcon>
                                <ListItemText primary="Profile" sx={{ opacity: open ? 1 : 0 }} />

                            </ListItemButton>
                        </Paper>
                    </Tooltip>


                    <Tooltip title="Search" placement="right-start">
                        <Paper elevation={5} style={{ margin: 10 }}>
                            <ListItemButton onClick={handleWorkProgress} href='/search'
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}>
                                    <SearchIcon />
                                </ListItemIcon>
                                <ListItemText primary="Search" sx={{ opacity: open ? 1 : 0 }} />

                            </ListItemButton>
                        </Paper>
                    </Tooltip>



                    <Tooltip title="Share Post on LinkedIn" placement="right-start">
                        <Paper elevation={5} style={{ margin: 10 }}>
                            <ListItemButton href='/sharePost'
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}

                            >
                                <ListItemIcon sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}>
                                    <LinkedInIcon />
                                </ListItemIcon>
                                <ListItemText primary="Share Post on LinkedIn" sx={{ opacity: open ? 1 : 0 }} />


                            </ListItemButton >
                        </Paper>
                    </Tooltip>

                    <Tooltip title="Share Tweet on Twitter" placement="right-start">
                        <Paper elevation={5} style={{ margin: 10 }}>
                            <ListItemButton href='/sharetweet'
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}

                            >
                                <ListItemIcon sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}>
                                    <XIcon />
                                </ListItemIcon>
                                <ListItemText primary="Share Tweet on Twitter" sx={{ opacity: open ? 1 : 0 }} />


                            </ListItemButton >
                        </Paper>
                    </Tooltip>

                    <Tooltip title="Share Post on Facebook" placement="right-start">
                        <Paper elevation={5} style={{ margin: 10 }}>
                            <ListItemButton href='/sharefacebook'
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}

                            >
                                <ListItemIcon sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}>
                                    <FacebookIcon />
                                </ListItemIcon>
                                <ListItemText primary="Share Post on Facebook" sx={{ opacity: open ? 1 : 0 }} />


                            </ListItemButton >
                        </Paper>
                    </Tooltip>

                    <Tooltip title="Share Post on Instagram" placement="right-start">
                        <Paper elevation={5} style={{ margin: 10 }}>
                            <ListItemButton href='/shareinstagram'
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}

                            >
                                <ListItemIcon sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}>
                                    <InstagramIcon />
                                </ListItemIcon>
                                <ListItemText primary="Share Post on Instagram" sx={{ opacity: open ? 1 : 0 }} />


                            </ListItemButton >
                        </Paper>
                    </Tooltip>

                    <Tooltip title="Message History" placement="right-start">
                        <Paper elevation={5} style={{ margin: 10 }}>
                            <ListItemButton onClick={handleWorkProgress}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}>
                                    <MailIcon />
                                </ListItemIcon>
                                <ListItemText primary="Message History" sx={{ opacity: open ? 1 : 0 }} />


                            </ListItemButton>
                        </Paper>
                    </Tooltip>

                    <Tooltip title="Your Connections" placement="right-start">
                        <Paper elevation={5} style={{ margin: 10 }}>

                            <ListItemButton href='/connections' onClick={handleWorkProgress}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Your Connections" sx={{ opacity: open ? 1 : 0 }} />


                            </ListItemButton >
                        </Paper>
                    </Tooltip>

                </List>
                <Divider />

            </Drawer>


        </Box>


    )
}