import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import { Paper, TextField } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    //border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

function ChildModal() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        // setOpen(false);
        setOpen(false);
    };

    return (
        <React.Fragment>
            
            <Box display="flex" justifyContent="space-evenly" m="0.5px">
                <Button variant='contained' color='success' onClick={handleOpen}>Send</Button>
                
            </Box>


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style, width: 300 }}>
                    <h2 id="child-modal-title">Message Sent Successfully</h2>
                    <Button color="error" onClick={handleClose}>Close</Button>
                </Box>
            </Modal>
        </React.Fragment>
    );
}

export const SendMessage = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Paper elevation={10} style={{margin:1 , width:"200px" , height:"40px" , justifyContent:"center" , alignItems:"center" , display:"flex"}}>
            <Button fullWidth variant="contained" color='success' onClick={handleOpen}>Send Message</Button>
            </Paper>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
            >
                <Box flexDirection="column" sx={{ ...style, width: 600, height: 600, display: "flex", marginTop: "10px" }}>
                    <h2 id="send_message">Send Message</h2>
                    <TextField
                        id="standard-multiline-static"
                        label="Type your message here!!!"
                        multiline
                        rows={15}
                        //defaultValue="Default Value"
                        variant="standard"
                        border="2px solid black"
                    />
                    
                    <ChildModal style={{marginTop:'10px'}}/>
                    <Box display="flex" justifyContent="space-evenly" m="15px">
                        <Button variant='contained' color='error' marginTop="10px" onClick={handleClose} >Close</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default SendMessage;