import React from 'react';
import { Box, Grid, Card, CardContent, CardMedia, Typography, CardActionArea } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import fb1 from '../../Images/fbuse3.jpg'
import fb2 from '../../Images/fb2.jpg'
import insta1 from '../../Images/instause.svg'
import insta2 from '../../Images/instause2.png'

const cardsData = [
  { title: 'Sign In with LinkedIn', description: 'Explore the new world of LinkedIn', image: 'https://accountgram-production.sfo2.cdn.digitaloceanspaces.com/nubelaco_ghost/2023/10/TLC_How_to_search_linkedin_without_loginArtboard_3--1-.png' },
];
const cardsData2 = [
  { title: 'LinkedIn Post', description: 'You can easily share your post', image: 'https://assets-global.website-files.com/5f7ece8a7da656e8a25402bc/6410842bd4f442a63b6d25a9_How%20to%20turn%20a%20LinkedIn%20comment%20into%20a%20post%20(1).png' },

  { title: 'Sign In with Twitter', description: 'Explore the new world of Twitter', image: 'https://cronicle.press/static/3024b974a7715a16e1437a074afd46dd/6b085/X-logo-website-login-page-image.jpg' },
];
const cardsData3 = [
  { title: 'Share Tweet', description: 'You can easily share your Tweet!!!!', image: 'https://www.digitaltrends.com/wp-content/uploads/2023/02/twitter-down-over-daily-limit-tweets.jpg?p=1' },

  { title: 'Sign In with Facebook', description: 'Explore the new world of Facebook', image: fb1 },
];
const cardsData4 = [
  { title: 'Facebook Post', description: 'You can easily share your post', image: fb2 },

  { title: 'Sign In with Instagram', description: 'Explore the new world of Instagram', image: insta1 },
];
const cardsData5 = [
  { title: 'Instagram Post', description: 'You can easily share your post', image: insta2 },

];


const CardGroup = () => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMdScreen = useMediaQuery('(max-width:1777px)');

  return (
    <Box sx={{ p: 2 }}>
    <Grid container spacing={3} style={{padding:isSmallScreen?'0px':'40px'}} justifyContent="space-around">
      {cardsData.map((card, index) => (
        <div item style={{display:'flex', justifyContent:'center',borderRadius:'10px'}} key={index} >
            <div style={{display:'row'}}>
          <Card sx={{ maxWidth: 500 }} style={{backgroundColor:'#F9F9F9', padding:'20px', margin:'20px', boxShadow:'none',borderRadius:'10px' }}>
              
              <CardContent>
                <Typography gutterBottom variant="h2" style={{fontWeight:'bold'}} component="div">
                  {card.title}
                </Typography>
                <p  style={{fontSize:'20px',margin:'0px'}} color="text.secondary">
                  {card.description}
                </p>
              </CardContent>
              <CardMedia
                component="img"
                height="300"
                image={card.image}
                alt={card.title}
              />
          </Card>
          </div>
        </div>
      ))}
     
    </Grid>
    <Grid container spacing={3} style={{padding:isSmallScreen?'0px':'40px'}} justifyContent="space-around">
      {cardsData2.map((card, index) => (
         <div item style={{display:'flex', justifyContent:'center',borderRadius:'10px'}} key={index} >
         <div style={{display:'row'}}>
       <Card sx={{ maxWidth: 500 }} style={{backgroundColor:'#F9F9F9', padding:'20px', margin:'20px', boxShadow:'none',borderRadius:'10px' }}>
           
           <CardContent>
             <Typography gutterBottom variant="h2" style={{fontWeight:'bold'}} component="div">
               {card.title}
             </Typography>
             <p  style={{fontSize:'20px',margin:'0px'}} color="text.secondary">
               {card.description}
             </p>
           </CardContent>
           <CardMedia
             component="img"
             height="300"
             image={card.image}
             alt={card.title}
           />
       </Card>
       </div>
     </div>
      ))}
    </Grid>
    <Grid container spacing={3} style={{padding:isSmallScreen?'0px':'40px'}} justifyContent="space-around">
      {cardsData3.map((card, index) => (
         <div item style={{display:'flex', justifyContent:'center',borderRadius:'10px'}} key={index} >
         <div style={{display:'row'}}>
       <Card sx={{ maxWidth: 500 }} style={{backgroundColor:'#F9F9F9', padding:'20px', margin:'20px', boxShadow:'none',borderRadius:'10px' }}>
           
           <CardContent>
             <Typography gutterBottom variant="h2" style={{fontWeight:'bold'}} component="div">
               {card.title}
             </Typography>
             <p  style={{fontSize:'20px',margin:'0px'}} color="text.secondary">
               {card.description}
             </p>
           </CardContent>
           <CardMedia
             component="img"
             height="300"
             image={card.image}
             alt={card.title}
           />
       </Card>
       </div>
     </div>
      ))}
    </Grid>
    <Grid container spacing={3} style={{padding:isSmallScreen?'0px':'40px'}} justifyContent="space-around">
      {cardsData4.map((card, index) => (
         <div item style={{display:'flex', justifyContent:'center',borderRadius:'10px'}} key={index} >
         <div style={{display:'row'}}>
       <Card sx={{ maxWidth: 500 }} style={{backgroundColor:'#F9F9F9', padding:'20px', margin:'20px', boxShadow:'none',borderRadius:'10px' }}>
           
           <CardContent>
             <Typography gutterBottom variant="h2" style={{fontWeight:'bold'}} component="div">
               {card.title}
             </Typography>
             <p  style={{fontSize:'20px',margin:'0px'}} color="text.secondary">
               {card.description}
             </p>
           </CardContent>
           <CardMedia
             component="img"
             height="300"
             image={card.image}
             alt={card.title}
           />
       </Card>
       </div>
     </div>
      ))}
    </Grid>
    <Grid container spacing={3} style={{padding:isSmallScreen?'0px':'40px'}} justifyContent="space-around">
      {cardsData5.map((card, index) => (
         <div item style={{display:'flex', justifyContent:'center',borderRadius:'10px'}} key={index} >
         <div style={{display:'row'}}>
       <Card sx={{ maxWidth: 500 }} style={{backgroundColor:'#F9F9F9', padding:'20px', margin:'20px', boxShadow:'none',borderRadius:'10px' }}>
           
           <CardContent>
             <Typography gutterBottom variant="h2" style={{fontWeight:'bold'}} component="div">
               {card.title}
             </Typography>
             <p  style={{fontSize:'20px',margin:'0px'}} color="text.secondary">
               {card.description}
             </p>
           </CardContent>
           <CardMedia
             component="img"
             height="300"
             image={card.image}
             alt={card.title}
           />
       </Card>
       </div>
     </div>
      ))}
    </Grid>
  </Box>
  )
}

export default CardGroup