import React from 'react'
import { BarChart } from '@mui/x-charts/BarChart';
import { Typography } from '@mui/material';

import { PieChart } from '@mui/x-charts/PieChart';
import { useEffect, useState } from 'react';

const linkedIn = [4000, 3000, 2000, 2780, 1890, 2390, 3490, 1500, 3450, 2900, 2000, 2500];
const twitter = [2400, 1398, 5000, 3908, 4800, 3800, 4300, 2500, 4000, 3500, 3000, 4000];
const xLabels = [
  'Jan',
  'Feb',
  'March',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];









const Section2Graphs = () => {

    const [twitterCount, setTwitterCount] = useState(0);
    const [linkedinCount, setLinkedinCount] = useState(0);
    const [fbCount, setFbCount] = useState(0);
    const [instaCount, setInstaCount] = useState(0);
  
    const [fbDataC, setFbDataC] = useState({});
    const [instaDataC, setInstaDataC] = useState({});
    const [linkdnDataC, setLinkdnDataC] = useState({});
    const [twitterDataC, setTwitterDataC] = useState({});
    const [processChartData, setProcessChartData] = useState(false);
    const [chartDataB, setChartDataB] = useState(null);
  
    const [showMessage, setShowMessage] = useState(false);
  
    function getTotalPostsByMonth(data) {
      const postCounts = {};
  
      const allPosts = data.flat();
  
      allPosts.forEach(post => {
        const date = new Date(post.date);
        const yearMonth = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
  
        if (postCounts[yearMonth]) {
          postCounts[yearMonth]++;
        } else {
          postCounts[yearMonth] = 1;
        }
      });
  
      return postCounts;
    }
  
    const userNo = () => {
      const email = localStorage.getItem('useremail');
      fetch(`${process.env.REACT_APP_URL}/totalposts`, {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({ email })
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          const { twitterPostCount, linkedinPostCount, fbPostCount, instaPostCount, fbDataArr, instaDataArr, linkdnDataArr, twitterDataArr } = data;
          setTwitterCount(twitterPostCount);
          setLinkedinCount(linkedinPostCount);
          setFbCount(fbPostCount);
          setInstaCount(instaPostCount);
  
          setFbDataC(getTotalPostsByMonth(fbDataArr));
          setInstaDataC(getTotalPostsByMonth(instaDataArr));
          setLinkdnDataC(getTotalPostsByMonth(linkdnDataArr));
          setTwitterDataC(getTotalPostsByMonth(twitterDataArr));
          setProcessChartData(true);
        })
        .catch(error => {
          console.error('Error fetching counts:', error);
        });
    };
  
    useEffect(() => {
        if (processChartData === true) {
          console.log(fbDataC, linkdnDataC);
      
          // Provide default labels if xLabels is empty
          const defaultLabels = ['No Data'];
      
          const xLabels = Array.from(
            new Set([
              ...Object.keys(fbDataC),
              ...Object.keys(linkdnDataC),
              ...Object.keys(instaDataC),
              ...Object.keys(twitterDataC),
            ])
          ).sort() || defaultLabels;
      
          // Provide default data if the data arrays are empty
          const fbData = xLabels.map(label => fbDataC[label] || 0);
          const instaData = xLabels.map(label => instaDataC[label] || 0);
          const twitterData = xLabels.map(label => twitterDataC[label] || 0);
          const linkdnData = xLabels.map(label => linkdnDataC[label] || 0);
      
          console.log(fbData, instaData, twitterData, linkdnData, xLabels);
      
          setChartDataB({
            series: [
              { data: fbData.length > 0 ? fbData : [0], label: 'Facebook', id: 'facebookId' },
              { data: instaData.length > 0 ? instaData : [0], label: 'Instagram', id: 'instaId' },
              { data: twitterData.length > 0 ? twitterData : [0], label: 'Twitter', id: 'twitterId' },
              { data: linkdnData.length > 0 ? linkdnData : [0], label: 'LinkedIn', id: 'linkedInId' },
            ],
            xAxis: [{ data: xLabels.length > 0 ? xLabels : defaultLabels, scaleType: 'band' }],
          });
        }
    }, [processChartData, fbDataC, instaDataC, linkdnDataC, twitterDataC]);
  
    useEffect(() => {
      userNo();
    }, []);
  
    useEffect(() => {
      if (twitterCount === 0 && linkedinCount === 0) {
        setShowMessage(true);
      } else {
        setShowMessage(false);
      }
    }, [twitterCount, linkedinCount]);
  
  
    const data = [
        { id: 0, value: twitterCount, label: 'Twitter Posts' },
        { id: 1, value: linkedinCount, label: 'LinkedIn Posts' },
        { id: 2, value: fbCount, label: 'Facebook Posts' },
        { id: 3, value: instaCount, label: 'Instagram Posts' },
      ];
    
      const dataStatic = [
        { id: 0, value: 1, label: 'Dummy Twitter Posts' },
        { id: 1, value: 1, label: 'Dummy LinkedIn Posts' },
        { id: 2, value: 1, label: 'Dummy Facebook Posts' },
        { id: 3, value: 1, label: 'Dummy Instagram Posts' },
      ];
    
      const chartData = twitterCount === 0 && linkedinCount === 0 ? dataStatic : data;

  return (
    <div style={{ marginTop: '100px', marginLeft: '100px', marginRight: '10px' }}>
    <div style={{ display: 'flex', justifyContent:'space-around' , flexWrap: 'wrap' }}>
      <div style={{ maxWidth: '600px', width: '100%',height:'450px',marginTop: '20px', marginRight: '10px', backgroundColor:'white', boxShadow: '0 2px 4px rgba(0,0,0,0.5)',borderRadius:'10px' }}>
       <div style={{borderBottom:'1px solid grey', padding:'5px',margin:'5px'}}>
        <p style={{fontSize:'18px',fontWeight:'bold', margin:'0px'}}>Monthly Posting Data</p>
       </div>
       {chartDataB ? (
            <BarChart
            height={400}
            style={{ width: '100%', height: '600px', minWidth: '400px' }}
            series={chartDataB?.series || [{ data: [], label: 'No Data', id: 'noDataId' }]}
            xAxis={chartDataB?.xAxis || [{ data: [], scaleType: 'band' }]}
          />
          
          ) : (
            <p>Loading chart data...</p>
          )}
       
      </div>
  
      <div style={{ maxWidth: '500px', width: '100%', marginTop: '20px', height:'450px',  backgroundColor:'white', boxShadow: '0 2px 4px rgba(0,0,0,0.5)',borderRadius:'10px'}}>
      <div style={{borderBottom:'1px solid grey', padding:'5px',margin:'5px'}}>
        <p style={{fontSize:'18px',fontWeight:'bold', margin:'0px'}}>Posting per Social Media</p>
       </div>
       <div style={{marginTop:'150px',maxHeight:'600px'}}>
        <PieChart
          series={[
            {
              data: chartData,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
        //   width={500}
        marginTop={200}
          height={200}
          style={{width:'100%',minWidth:'400px',  }}

        />
        </div>
      </div>
    </div>
  
    {showMessage && (
      <div style={{ marginTop: '10px', color: 'gray', textAlign: 'center' }}>
        This is static data. Once you share the post, it will be updated.
      </div>
    )}
  </div>
  )
}

export default Section2Graphs