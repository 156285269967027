import React, { useEffect, useState } from 'react'
import { Box, Button, Paper, TextField, Typography ,Grid} from '@mui/material';
import { Header } from './Header';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Avatar from '@mui/material/Avatar';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Swal from 'sweetalert2'
import axios from 'axios'
import dayjs from 'dayjs'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


export const UserProfile = () => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const accessToken = localStorage.getItem('accessToken');
  const userInfo = localStorage.getItem('userInfo')
  const userSub = localStorage.getItem('userSub')
  const useremail = localStorage.getItem('useremail')
  const userName = localStorage.getItem('userName')
  const userPhoto = localStorage.getItem('userPhoto')
  // console.log(useremail)

  const [gender, setgender] = React.useState('');
  const [dob, setDob] = useState(null);
  const [mobile, setMobile] = useState('');
  //const [country, setCountry] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [countryCode, setCountrycode] = useState('');


  const [genderError, setGenderError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [countryCodeError, setCountryCodeError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [pinCodeError, setPinCodeError] = useState(false);


  const handleFieldError = () => {
    Swal.fire({
        title: "Error",
        text: "Please fill all fields",
        icon: "warning",
        showCancelButton: false, // Set to false to hide the cancel button
        showConfirmButton: true, // Set to true to show the confirm (OK) button
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK" // Change the text of the confirm button to "OK"
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("email error");
          
        }
      });
}


const handleSuccessful = () => {
  Swal.fire({
      title: "successful.",
      text: "Data Saved Successfully.",
      icon: "success",
      showCancelButton: false, // Set to false to hide the cancel button
      showConfirmButton: true, // Set to true to show the confirm (OK) button
      confirmButtonColor: "#3085d6",
      confirmButtonText: "OK" ,// Change the text of the confirm button to "OK"
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("email error");
      }
    });
}

const handleError = () => {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: "Something went wrong!",
  }).then((result) => {
    if (result.isConfirmed) {
      // Reload the window after clicking "OK"
      window.location.reload();
    }
  });
}

const handleSubmitData = () => {
  //const confirmed = window.confirm('Are you sure you want to post?');
  try {
    Swal.fire({
      title: "Are you sure?",
      text: "Submit Data",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {
        if (useremail) {
            uploadData(); // Call uploadData if user confirms
            handleSuccessful()
          }else if(!gender && !dob && !mobile && !countryCode && !address && !city && !state && !pinCode) {
            handleFieldError()
          } else {
            // Handle cancellation if user clicks 'No'
            console.log("Submission cancelled by user");
            handleError()
          }
        }
     
    });
  } catch (error) {
    handleError()
  } 
}



  const handleSubmit = () => {
    // Reset error flags
    setGenderError(false);
    setDobError(false);
    setMobileError(false);
    setCountryCodeError(false);
    setAddressError(false);
    setCityError(false);
    setStateError(false);
    setPinCodeError(false);

    // Check for empty required fields
    if (!gender) setGenderError(true);
    if (!dob) setDobError(true);
    if (!mobile) setMobileError(true);
    if (!countryCode) setCountryCodeError(true);
    if (!address) setAddressError(true);
    if (!city) setCityError(true);
    if (!state) setStateError(true);
    if (!pinCode) setPinCodeError(true);

    // Check if all fields are filled
    const allFieldsFilled =
      gender &&
      dob &&
      mobile &&
      countryCode &&
      address &&
      city &&
      state &&
      pinCode

      handleSubmitData();
    
  };



  const uploadData = () => {
    console.log("^^^^^^^^^^^^^^^^^^^^^^^^^");
    fetch(`${process.env.REACT_APP_URL}/uploaddata`,

      {
        method: "POST",
        body: JSON.stringify({
          "email": useremail,
          "gender": gender,
          "dob": dob,
          "mobile": mobile,
          "country": countryCode,
          "address": address,
          "city": city,
          "state": state,
          "pinCode": pinCode,
        }),
        headers: { "Content-type": "application/json" }
      })
      .then(response => { console.log(response) })
      .catch(error => { handleError()})
  }

  useEffect(() => {
    axios.post(
      `${process.env.REACT_APP_URL}/getuserdata`,
      {
        email: useremail // Assuming useremail is defined somewhere in your component's state or props
      },
      {
        headers: { "Content-type": "application/json" }
      }
    )
    .then(x => {
      console.log(x.data); // Logging the response data
      console.log(new Date(x.data.dob)); // Logging the response data
      // Further processing of the response data can be done here
      setAddress(x?.data?.address)
      setCity(x?.data?.city)
      setDob(dayjs(x?.data?.dob))
      setgender(x?.data?.gender)
      setState(x?.data?.state)
      setMobile(x?.data?.mobile)
      setCountrycode(x?.data?.countryCode)
      setPinCode(x?.data?.pincode)
    })
    .catch(error => {
      console.error('Error fetching user data:', error);
      handleError(); // Assuming handleError() is a function to handle errors
    });
  }, []);

useEffect(()=>{
console.log(dob)
},[dob])
  const handleMobileChange = (e) => {
    const value = e.target.value;
    const mobileRegex = /^\d{0,10}$/; // Allows only up to 10 digits

    if (mobileRegex.test(value)) {
      setMobile(value);
    }
  }

  const handleCityChange = (e) => {
    const value = e.target.value;
    const cityRegex = /^[a-zA-Z\s]*$/; // Allows only letters and spaces

    if (cityRegex.test(value)) {
      setCity(value);
    }
  }

  const handleStateChange = (e) => {
    const value = e.target.value;
    const stateRegex = /^[a-zA-Z\s]*$/; // Allows only letters and spaces

    if (stateRegex.test(value)) {
      setState(value);
    }
  }

  const handleCountryCodeChange = (e) => {
    const value = e.target.value;
    const regex = /^[+\d]*$/; // Regex to allow only + and numbers

    if (regex.test(value)) {
      setCountrycode(value);
    }
  };

  return (
    <Box sx={{ display: 'flex',minHeight:'100vh',height:'100%', width: "100%" }}>

      <Header />

      <Box component="main" sx={{ p: isSmallScreen?1:5, height: "100%", width: "100%"  }} marginTop="80px" marginBottom='80px' display="flex" flexDirection="row" alignItems="center" justifyContent="center" flexWrap='wrap' >
        <Paper elevation={10} sx={{ display: "flex", height:  isSmallScreen?'40%':"90%", width: isSmallScreen?'80%':"40%", mr: 2,m:2, borderRadius: "8%", alignItems: "center", justifyContent: "center" }} >
          <Box height="80%" width="90%"
            sx={{
              backgroundImage: "url('https://toplad.in/blog/cs/wp-content/uploads/sites/4/2022/04/8.png')",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center", // Center the background image

            }}
          >
          </Box>

        </Paper>

        <Paper
      elevation={10}
      sx={{
        display: 'flex',
       
        width: isSmallScreen ? '98%' : '50%',
        mr: 1,
        m:2,
        p: isSmallScreen ? 1 : 5,
        pt: 5,
        pb:2,
        mt:2,
        mb:5,
        flexDirection: 'column',
        borderRadius: '8%',
        margin: '0 auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          height: '10%',
          width: '100%',
          mr: 1,
          bgcolor: '#FFC470',
          alignItems: 'center',
          mb: 2,
          borderRadius: '25px',
        }}
      >
        <Typography style={{ fontWeight: 'bold', color: '#4793AF', fontSize: '20px' }}>
          GENERAL INFORMATION
        </Typography>
      </Box>

      <Grid container sx={{paddingTop:'20px',paddingBottom:'20px'}} spacing={3}>
        <Grid item xs={12} md={6}>
          <Box display="flex" alignItems="center">
            <Avatar sx={{ mr: '10px' }} alt="Cindy Baker" src={localStorage.getItem('userPhoto')} />
            <TextField
              id="outlined-read-only-input"
              fullWidth
              label="Name"
              defaultValue={userName}
              InputProps={{ readOnly: true }}
            />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-read-only-input"
            fullWidth
            label="Email"
            defaultValue={useremail}
            InputProps={{ readOnly: true }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={gender}
              label="Gender"
              onChange={(e) => setgender(e.target.value)}
            >
              <MenuItem value="Men">Men</MenuItem>
              <MenuItem value="Women">Women</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="DOB"
              fullWidth
              value={dob}
              onChange={(newValue) => setDob(newValue)}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-read-only-input"
            fullWidth
            label="Country Code"
            type="tel"
            inputProps={{ pattern: '[+0-9]*', inputMode: 'numeric' }}
            value={countryCode}
            onChange={handleCountryCodeChange}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            id="outlined-read-only-input"
            fullWidth
            label="Mobile No"
            type="tel"
            value={mobile}
            onChange={handleMobileChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="outlined-read-only-input"
            fullWidth
            label="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            id="outlined-read-only-input"
            fullWidth
            label="City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            id="outlined-read-only-input"
            fullWidth
            label="State"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <TextField
            id="outlined-read-only-input"
            fullWidth
            label="Pin Code"
            type="number"
            value={pinCode}
            onChange={(e) => setPinCode(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} display='flex' justifyContent='center'>
          <Button
            variant="contained"
            color="success"
            sx={{ mt: 4, borderRadius: '50px' }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Paper>



      </Box>

    </Box >

  )
}
export default UserProfile;
