import { Box, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SetPassword } from './SetPassword'
import Swal from 'sweetalert2'
import axios from 'axios'

export const Loader = () => {

    const navigate = useNavigate()




    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const handleError = () => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        }).then((result) => {
          if (result.isConfirmed) {
            // Reload the window after clicking "OK"
            window.location.reload();
          }
        });
      }


      const handleLoginexist = () => {
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Email is already present. Please Sign In.",
          }).then((result) => {
            if (result.isConfirmed) {
              // Reload the window after clicking "OK"
              window.location.reload();
            }
          });
    }




    //check if data is already present in local storage or not
    const accessToken = localStorage.getItem('accessToken');
    const userInfo = localStorage.getItem('userInfo')
    const userSub = localStorage.getItem('userSub')
    const useremail = localStorage.getItem('useremail')
    const userName = localStorage.getItem('userName')
    const userPhoto = localStorage.getItem('userPhoto')
    console.log(userSub);
    console.log(accessToken);



    //code from url
    const token = new URLSearchParams(window.location.search);
    const value = token.get('code');
    console.log(value);




    //AccessToken api^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
    // const accessTokenAPI = () => {
        
    //     const value = token.get('code');
    //     console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^");
    //     console.log("==============", accessToken)
    //     if (accessToken === "undefined" || accessToken === "null" || accessToken === null) {
    //         // if (!accessToken) {
    //         console.log("^^^^^^^^in access Token")
    //         fetch("https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&code=" + value + "&redirect_uri=https%3A%2F%2Fshadowbox.ai%2Floader&client_id=77mwnchaglz2x0&client_secret=qhvPKywg07tRGFYx"
    //         )
    //             .then(resp => resp.json())
    //             .then(json => {
    //                 const accessToken = json.access_token
    //                 console.log("In loader^^^^", accessToken)
    //                 localStorage.setItem('accessToken', accessToken);
    //                 userInfoAPI()

    //                 //setHaveToken(accessToken);

    //             }).catch(err => console.log('error in AccessTokenApi = ',err))
    //     }
    // }
/////////// new VV          original ^^^
const accessTokenAPI = async () => {
    const value = token.get('code');
    const useremail = localStorage.getItem('useremail')
    console.log(useremail,"==============", accessToken);
    

    if (accessToken === "undefined" || accessToken === "null" || accessToken === null) {
        console.log("in 1",localStorage.getItem('accessToken'),localStorage.getItem('accessToken') === undefined);
        if(localStorage.getItem('accessToken') === null || localStorage.getItem('accessToken') === 'undefined'){
            console.log("in 2")
        try {
            const response = await axios.post(`${process.env.REACT_APP_URL}/getLinkedInAccessToken`, { code: value,useremail:useremail });
            const accessToken = response.data.access_token;
            console.log("RRRRRRRR",response)
            console.log("In loader^^^^", accessToken);
            localStorage.setItem('accessToken', accessToken);
            // localStorage.setItem('userSubLink',)
            userInfoAPI();

        } catch (error) {
            console.log('error in AccessTokenApi = ', error);
        }
    }
    }
}






    // User Information API^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
    // const userInfoAPI = async () => {
    //     console.log(userSub);
    //     // if (userSub === "undefined" || userSub === "null" || userSub === null) {
    //         const accessToken = localStorage.getItem('accessToken');
    //         if (accessToken) {
    //             console.log("accessToken : ", accessToken)
    //             console.log("In if of userInfo")
    //             fetch(`https://api.linkedin.com/v2/userinfo?oauth2_access_token=${accessToken}`)
    //                 .then(response => response.json())
    //                 .then(result => {
    //                     const userInfo = result;
    //                     console.log(userInfo)
    //                     const userSub = result.sub;
    //                     const useremail = result.email;
    //                     const userName = result.name;
    //                     const userPhoto = result.picture;
    //                     //console.log(userInfo);
    //                     localStorage.setItem('userInfo', userInfo)
    //                     localStorage.setItem('userSub', userSub)
    //                     localStorage.setItem('userPhoto', userPhoto)
    //                     localStorage.setItem('useremail', useremail)
    //                     localStorage.setItem('userName', userName)
    //                     loginCheck()
    //                 })
    //                 .catch(error => console.error(error));
    //         }

    //     // }

    // }

    const savelinkdntokens = () => {

        const LinkdnToken = localStorage.getItem('accessToken')
        const LinkdnProfile = localStorage.getItem('userNameLink') 
        const LinkdnSub = localStorage.getItem('userSubLink') 
    
        fetch(`${process.env.REACT_APP_URL}/savelinkdntokens`,
    
          {
            method: "POST",
            body: JSON.stringify({
              useremail: useremail,
              LinkdnToken: LinkdnToken,
              LinkdnProfile: LinkdnProfile,
              LinkdnSub: LinkdnSub
            }),
            headers: { "Content-type": "application/json" }
          })
          .then(response => { console.log(response) })
          .catch(error => { console.log(error) })
      }
    

    const userInfoAPI = async () => {
        const accessToken = localStorage.getItem('accessToken');
        console.log('userinfo api')
        if (accessToken) {
            fetch(`${process.env.REACT_APP_URL}/userinfo?accessToken=${accessToken}`)
                .then(response => response.json())
                .then(result => {
                    if(result.error){
                        if(result.error === 'Access token is expired or invalid'){
                            localStorage.removeItem('accessToken')
                            accessTokenAPI()
                            
                        }
                    }
                    console.log(result,"------result")
                    const userInfo = result;
                    const userSub = result.sub;
                    const useremail = result.email;
                    const userName = result.name;
                    const userPhoto = result.picture;
                    localStorage.setItem('userInfo', userInfo);
                    localStorage.setItem('userSubLink', userSub);
                    localStorage.setItem('userPhotoLink', userPhoto);
                    localStorage.setItem('useremailLink', useremail);
                    localStorage.setItem('userNameLink', userName);
                    savelinkdntokens()

                    loginCheck();
                })
                .catch(error => console.error(error));
        }
    }

    // const [haveEmail, setHaveEmail] = useState('')
    const [haveToken, setHaveToken] = useState('')



    useEffect(() => {
        const fetchdata = async () => {
            // localStorage.clear()
            try {
                console.log("^^^^^^^^^^^^^^^^^^");
                await accessTokenAPI();
            } catch (error) {
                console.log("Error in asynchhhhhhhhhhhhh", error)
            }
        };

        fetchdata();
    }, []);





    const loginCheck = async () => {
        try {
            const useremail = localStorage.getItem('useremail')
           

            console.log("in logincheck 129", useremail)
            const response = await fetch(`${process.env.REACT_APP_URL}/logincheck`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: useremail }),
            }).then(response => response.json())
                .then(result => {
                    console.log("^^^^^^^^^^^^^^^^result 138", result)
                    if (result === "True") {
                        console.log("User already present!!!!!!");
                       handleLoginexist()
                        // updatedAccessToken()  REMOVED BY ANIKET
                        navigate("/signin")
                        //localStorage.clear();

                    } else if (result === "False") {
                        //addUserData()
                        //console.log("ddddddddddd", localStorage.getItem('useremail'));
                        navigate("/setpassword")

                    } else if (result === "Signup") {
                        // updatedAccessToken();
                        navigate("/sharepost")

                    } else {
                        console.log("Login successfull")
                    }

                });


        } catch (error) {
            console.error("Error during login:", error);
            handleError()
            navigate("/")
        }


    }




    const updatedAccessToken = () => {
        try {
            const useremail = localStorage.getItem('useremail')
            const accessToken = localStorage.getItem('accessToken')
            const userSub = localStorage.getItem('userSub')
            const userPhoto = localStorage.getItem('userPhoto')
            const response = fetch(`${process.env.REACT_APP_URL}/linkedinaccesstoken`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: useremail, accessToken: accessToken , userSub: userSub , userPhoto: userPhoto }),
            }).then(response => response.json())
                .then(result => {
                    // console.log(result)
                    if (result === "False") {
                        console.log("Updated AccessToken failedddd");

                    } else {
                        console.log("Updated AccessToken Suceessfulllllllllll")

                    }

                });

        } catch (error) {
            console.error("Error during login:", error);
            handleError()
            navigate("/")
        }
    }






    const paperStyle = { height: "50%", width: "40%", borderRadius: 20 };
    return (
        <Paper elevation={10} style={{ width: "80%", height: "80%" }} sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 10, marginLeft: 20 }}>
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" height="100%" width="100%" bgcolor="#007F73">

                <Box height="80%" width="40" sx={{
                    backgroundImage: "url('https://cdn5.vectorstock.com/i/1000x1000/56/34/graphic-cartoon-character-of-loading-vector-46115634.jpg')",
                    height: 400,
                    width: 500,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",

                }}>

                </Box>
                <Paper elevation={10} style={paperStyle} sx={{ margin: 2, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                    <Box>
                        <Typography sx={{ fontSize: "40px", fontWeight: "1000", margin: 1, color: "orange" }}>
                            Sorry to
                        </Typography>
                        <Typography sx={{ fontSize: "40px", fontWeight: "1000", margin: 1, color: "purple" }}>
                            make you wait
                        </Typography>
                    </Box>
                    <Box>
                        <Typography sx={{ fontSize: "15px", fontWeight: "400", margin: 1, color: "red" }}>
                            Redirecting page pease wait
                        </Typography>
                    </Box>
                </Paper>

            </Box>
        </Paper>
    )
}

export default Loader;
