import { Box, Button, Paper, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'

export const ForgotPassword = () => {

    const [error, setError] = useState('');
    const [email, setEmail] = useState('')
    const [otp, setOtp] = useState('')
    const [message, setMessage] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const navigate = useNavigate()
    const [otpTimestamp, setOtpTimestamp] = useState(0);

    const handleFieldError = () => {
        Swal.fire({
            title: "Error",
            text: "Please fill all fields",
            icon: "warning",
            showCancelButton: false, // Set to false to hide the cancel button
            showConfirmButton: true, // Set to true to show the confirm (OK) button
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK" // Change the text of the confirm button to "OK"
          }).then((result) => {
            if (result.isConfirmed) {
              console.log("Empty field error");
              
            }
          });
    }


    const handleWrongEmailError = () => {
        Swal.fire({
            title: "Error",
            text: "Email not found",
            icon: "warning",
            showCancelButton: false, // Set to false to hide the cancel button
            showConfirmButton: true, // Set to true to show the confirm (OK) button
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK" // Change the text of the confirm button to "OK"
          }).then((result) => {
            if (result.isConfirmed) {
              console.log("email error");
              window.location.reload();
            }
          });
    }

    
    const handleError = () => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        }).then((result) => {
          if (result.isConfirmed) {
            // Reload the window after clicking "OK"
            window.location.reload();
          }
        });
      }

      const handleCodeSend = () => {
        Swal.fire({
            title: "successful.",
            text: "Verification code send successfully.",
            icon: "success",
            showCancelButton: false, // Set to false to hide the cancel button
            showConfirmButton: true, // Set to true to show the confirm (OK) button
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK" ,// Change the text of the confirm button to "OK"
          }).then((result) => {
            if (result.isConfirmed) {
              console.log("Verification Code sent");
            }
          });
    }

    const handleCodeVerify = () => {
        Swal.fire({
            title: "successful.",
            text: "Verification code verified successfully.",
            icon: "success",
            showCancelButton: false, // Set to false to hide the cancel button
            showConfirmButton: true, // Set to true to show the confirm (OK) button
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK" ,// Change the text of the confirm button to "OK"
          }).then((result) => {
            if (result.isConfirmed) {
              console.log("Code verified");
            }
          });
    }

    const handleCodeExpire = () => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Verification Code expired. Resend the Code",
        }).then((result) => {
          if (result.isConfirmed) {
            // Reload the window after clicking "OK"
            console.log("Code expired.");
          }
        });
      }


      const handleWrongCode = () => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Incorrect Verification Code",
        }).then((result) => {
          if (result.isConfirmed) {
            // Reload the window after clicking "OK"
            window.location.reload();
          }
        });
      }
    
      const handleErrorDynamic = (title,text) => {
        Swal.fire({
          icon: "error",
          title: title,
          text: text,
        }).then((result) => {
          if (result.isConfirmed) {
            // Reload the window after clicking "OK"
            window.location.reload();
          }
        });
      }

      function validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      }


    const handleCheckEmail = () => {
        try {
          console.log(email,"--140")
          if(email === ''){
            handleErrorDynamic("Email Address Required","Please provide the email address associated with the account for which you have forgotten the password.")
            return
          }
          if (!validateEmail(email)) {
            handleErrorDynamic("Invalid Email Address", "Please enter a valid email address.");
            return;
          }

            const response = fetch(`${process.env.REACT_APP_URL}/otpsend`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: email }),
            }).then(response => response.json())
                .then(result => {
                    const userotp = result.combineData.otp;
                    const name = result.combineData.name
                    const picture = result.combineData.picture 
                    const email =result.combineData.email

                    localStorage.setItem('useremail' ,email)
                    localStorage.setItem('userName' , name)
                    localStorage.setItem('userPicture', picture)
                    localStorage.setItem('userotp', userotp)

                    if (!email) {
                        handleFieldError()
                    } else if (result === "False") {
                       handleWrongEmailError()
                    } else if (userotp === "undefined" || userotp === "null" || userotp === null) {
                        handleError()
                    } else {
                        console.log("OTP send successfully")
                        handleCodeSend()
                        setError("OTP is valid for 5 min only")
                        setOtpSent(true);
                        setOtpTimestamp(Date.now());
                    }
                });
        } catch (error) {
            console.error("Error To send OTP:", error);
            handleError()
        }
    }




    const handleSubmitOtp = () => {
        const userOtp = localStorage.getItem('userotp')

        const currentTime = Date.now();
        const otpValidityDuration = 5 * 60 * 1000;

        if (currentTime - otpTimestamp > otpValidityDuration) {
            handleCodeExpire()
            return;
        } else if (otp === userOtp) {
            handleCodeVerify()
            localStorage.setItem('userEmail' , email)
            console.log("Before set password " , (localStorage.getItem('userEmail')))
            navigate("/setforgotpassword")
           

        } else if (!otp) {
            handleFieldError()
        } else if (otp !== userOtp) {
            handleWrongCode()
        }
        else {
            handleError()
        }
    }


    const paperStyle = { height: "80%", width: "50%",minWidth:'300px' ,borderRadius: 20 };
    return (
        <Box sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", height: "100%", flexDirection: "row"}}>
            <Paper elevation={10} sx={{ display: "flex", justifyContent: "space-evenly",flexWrap:'wrap', alignItems: "center", minHeight: "80%", width: "80%", flexDirection: "row",  bgcolor:"#4CCD99" }}>
                <Box height="80%" margin='10px' width="50" sx={{
                    backgroundImage: "url('https://t3.ftcdn.net/jpg/04/92/75/18/360_F_492751838_Ybun2zwpQC8AZv11AwZLdXJk4cUrTt5z.jpg')",
                    height: 400,
                    width: 500,
                    borderRadius: '20px',
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",

                }}>

                </Box>
                <Paper elevation={20} margin='10px' style={paperStyle} sx={{ display: "flex", alignItems: "center", flexDirection: "column", paddingLeft:'20px', paddingRight:'20px' }}>

                    <Typography sx={{ fontSize: "50px", fontWeight: "100", margin: 1  }}>
                        Reset
                    </Typography>
                    <Typography sx={{ fontSize: "50px", fontWeight: "100", margin: 1 }}>
                        Your Password?
                    </Typography>
                    <Box
                        sx={{
                            marginTop: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}

                    >
                        <Box noValidate sx={{ mt: 1 }} >



                            <TextField
                                required
                                fullWidth
                                id="outlined-required"
                                label="Email"
                                placeholder="Enter Registered Email"
                                margin="2"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                sx={{ mt: 2, mb: 2 }}
                                
                                onClick={handleCheckEmail}
                            >
                                {otpSent ? "Resend OTP" : "Send OTP"}
                            </Button>
                            {otpSent && (
                            <>
                            
                                <TextField
                                    required
                                    fullWidth
                                    id="outlined-required"
                                    label="OTP"
                                    placeholder="Enter OTP"
                                    margin="2"
                                    onChange={(e) => setOtp(e.target.value)}
                                />
                                <Button
                                    variant="contained"
                                    // fullWidth
                                    color="success"
                                    sx={{ mt: 2, mb: 2 }}
                                    onClick={handleSubmitOtp}
                                >
                                    Verify OTP
                                </Button>
                            </>
                        )}
                        </Box>

                    </Box>
                </Paper>



            </Paper>
        </Box>
    )
}
