import React from 'react'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2'

import logo from '../../Images/logo.png'
import './cardSmall.css'

const cardStyle = {
  display: 'flex',
  maxWidth: 600,
  backgroundColor:'white',
  margin: 'auto',
  marginBottom: 20,
  padding: 10,
  boxShadow: '0 2px 4px rgba(0,0,0,0.5)',
  borderRadius: 8,
  overflow: 'hidden',
};

const imageStyle = {
  maxHeight:'60px',
  maxWidth:'60px',
  height:'60px',
  width:'60px',
  marginRight: 20,
};

const contentStyle = {
  flex: 1,
};

const DataCard = ({ image, title, description, number }) => {
  return (
    <div style={cardStyle}>
      <img style={imageStyle} src={image} alt="Card" />
      <div style={contentStyle}>
        <Typography variant="h5" component="div" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {description}
        </Typography>
      </div>
      <div style={{margin:'0px'}}>
        <p style={{fontSize:'30px', margin:'0px'}}>{number}</p>
      </div>
    </div>
  );
};

const cardStyle2 = {
  
    maxWidth: 600,
    backgroundColor: 'white',
    margin: 'auto',
    marginBottom: 20,
    padding: 10,
    boxShadow: '0 2px 4px rgba(0,0,0,0.5)',
    borderRadius: 8,
    overflow: 'hidden',
  };
  
  const imageStyle2 = {
    maxHeight: '60px',
    maxWidth: '60px',
    height: '60px',
    width: '60px',
    marginRight: 20,
  };
  
  const contentStyle2 = {
    display: 'flex',
    alignItems: 'center',
    justifyContent:'space-evenly',
    width:'100%'
  };
  
  const numberStyle2 = {
    fontSize: '30px',
    margin: '0',
    height: '30px',
  };

const DataCard2 = ({ image, title, description, number }) => {
    return (
        <div style={cardStyle2}>
        <div style={contentStyle2}>
          <img style={imageStyle2} src={image} alt="Card" />
          <p style={numberStyle2}>{number}</p>
        </div>
        <div style={{ width: '100%',marginTop:'20px' }}>
          <Typography variant="h5" component="div" style={{ textAlign: 'center' }} gutterBottom>
            {title}
          </Typography>
          <Typography variant="body2" style={{ textAlign: 'center' }} color="text.secondary">
            {description}
          </Typography>
        </div>
      </div>
    );
  };



const Section1DataCards = () => {

    const [twitterCount, setTwitterCount] = useState(0);
    const [linkedinCount, setLinkedinCount] = useState(0);
    const [fbCount, setFbCount] = useState(0);
    const [instaCount, setInstaCount] = useState(0);

    const [showMessage, setShowMessage] = useState(false);
  
  
    const data = [
        // {
        //   image: logo,
        //   title: 'Active users on application',
        //   description: 'This is a description of the card content.',
        //   number: `${localStorage.getItem('userCount')}`,
        // },
        {
          image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTjvzC_QRv6moAhgNb5C6e3yicKgFND1g2RwA&s',
          title: 'My Post on Facebook',
          description: 'Number of posts on FB',
          number:`${fbCount}`,
        },
        {
          image: 'https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png',
          title: 'My post on LinkedIn',
          description: 'Number of posts on LinkedIn',
          number:`${linkedinCount}`,
        },
        // {
        //   image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTI-h-e2hgz8mwGfCt4gvj4IgMG_wAUolVM6w&s',
        //   title: 'My followers on Twitter',
        //   description: 'This is a description of the card content.',
        //   number:'42',
        // },
        {
          image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTI-h-e2hgz8mwGfCt4gvj4IgMG_wAUolVM6w&s',
          title: 'Tweets on Twitter',
          description: 'Number of posts on Twitter',
          number:`${twitterCount}`,
        },
        {
          image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/768px-Instagram_logo_2016.svg.png',
          title: 'My post on Instagram',
          description: 'Number of posts on Instagram',
          number:`${instaCount}`,
        },
      ];
    
  
  
    // const linkedinTotalPost = () => {
    //   const email = localStorage.getItem('useremail')
    //   console.log("in total post frontend!!!!!!!!!!!!" , email);
    //   fetch(`${process.env.REACT_APP_URL}/totalposts`, {
    //     method: "POST",
    //     headers: { "Content-type": "application/json" },
    //     body: JSON.stringify({ email: email })
    //   })
    //     .then(response => {
    //       if (!response.ok) {
    //         throw new Error('Network response was not ok');
    //       }
    //       console.log(response);
    //       return response.json();
    //     })
    //     .then(data => {
    //       const {  linkedinPostCount } = data;
    //       setLinkedinCount(linkedinPostCount);
    //       console.log("updated",linkedinPostCount);
    //     })
    //     .catch(error => {
    //       console.error('Error fetching counts:', error);
    //     });
    // };
  
    useEffect(() => {
      userNo()
    }, []);
  
    // useEffect(() => {
    //   linkedinTotalPost()
    // }, []);
  
  
    const totalPost = () => {
      const email = localStorage.getItem('useremail')
      fetch(`${process.env.REACT_APP_URL}/totalposts`, {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({ email : email })
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          const { twitterPostCount, linkedinPostCount,fbPostCount,instaPostCount } = data;
          setTwitterCount(twitterPostCount);
          setLinkedinCount(linkedinPostCount);
          setFbCount(fbPostCount)
          setInstaCount(instaPostCount)
          console.log(twitterPostCount , linkedinPostCount,data);
        })
        .catch(error => {
          handleError()
        });
    };
  
    useEffect(() => {
      totalPost();
    }, []);
  
  
    
    const handleError = () => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      }).then((result) => {
        if (result.isConfirmed) {
          // Reload the window after clicking "OK"
          window.location.reload();
        }
      });
    }
  
  
    
  
    const userNo = () => {
      console.log("Dddddddddddddddd in userNo");
      fetch(`${process.env.REACT_APP_URL}/noofusers`, {
          method: "POST",
          headers: { "Content-type": "application/json" }
      })
          .then(response => {
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              return response.json();
          })
          .then(data => {
              console.log(data.count);
              const userCount = data.count;
              localStorage.setItem('userCount', userCount)
              // Log the count received from the backend
          })
          .catch(error => {
              console.error('Error fetching count:', error);
          });
  }

  return (
    <div style={{marginTop:'100px',marginLeft:'80px', marginRight:'10px'}}>
 <div style={{display:'flex', justifyContent:'center', flexWrap:'wrap'}}>
 <Grid container spacing={2} justifyContent="center">
      {data.map((item, index) => (
        <><Grid key={index} className='hide-below-420' item sm={12} md={6}  >
          <DataCard
            image={item.image}
            title={item.title}
            description={item.description}
            number={item.number}
          />
        </Grid>
         <Grid key={index} className='show-below-420' item xs={12} sm={6} md={4} >
         <DataCard2
           image={item.image}
           title={item.title}
           description={item.description}
           number={item.number}
         />
       </Grid></>
      ))}
    </Grid>
    </div>
    </div>
  )
}

export default Section1DataCards