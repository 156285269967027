 import React from 'react';
import {LoginSocialFacebook} from 'reactjs-social-login'
import {FacebookLoginButton} from 'react-social-login-buttons'


const Fbloader = () => {
  const responseFacebook = (response) => {
    console.log(response); // This will log the user's Facebook profile information
    // You can send this response to your backend for authentication
  };

  return (
    <div>
    
    </div>
  );
};

export default Fbloader;