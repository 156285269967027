import React,{useState,useEffect} from 'react'
import Box from '@mui/material/Box'
import axios from 'axios'
import { Button, Paper, TextField, Typography, colors } from '@mui/material';
import { Header } from '../Header'
import PostAddIcon from '@mui/icons-material/PostAdd';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Cookies from 'js-cookie';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CustomModal from '../CustomModal';
import DummyProfile from '../../../Images/DummyProfile2.jpg'
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Swal from 'sweetalert2'



const InstaLoginPosting = () => {

    // const FB_APP_ID = process.env.REACT_APP_FB_APP_ID
  const FB_APP_ID ='1136139144384934'

  const useremail = localStorage.getItem('useremail')
  console.log(useremail,"--useremail")

  useEffect(()=>{
    const useremail = localStorage.getItem('useremail')

    if (useremail === null) {
      Swal.fire({
        title: "Login Required",
        text: "You need to log in to Shadowbox to use this functionality.",
        icon: "warning", // "fail" should be "warning" or "error"
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Login"
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = '/signin'; // Correctly set the window location
        }
      });
    }
    
  },[])

  useEffect(()=>{
    if(localStorage.getItem('InstaToken')){
      console.log(localStorage.getItem('InstaToken'),"--------33")
      Cookies.set('instaAccessToken', localStorage.getItem('InstaToken'));
    }
    },[])


    const [userAccessToken,setUserAccessToken]=useState('')
    const [userId,setUserId]=useState('')
    const [image, setImage] = useState(null);
    const [pageId,setPageId] = useState('')
    const [instaPageId,setInstaPageId] = useState('')
    const [pageAccessToken,setPageAccessToken]= useState('')
    const [postText, setPostText] = useState('');
    const [edited, setEdited] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageBlobSrc,setImageBlobSrc]=useState('')
    const [sdkLoaded, setSdkLoaded] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [loadingAnime,setLoadingAnime] = useState(false)

    const [pageList,setPageList] = useState([])
    const [instaProfileList,setInstaProfileList] = useState([])
  const [pageName,setPageName] = useState('')
  const [dropdownValue,setDropdownValue] = useState('')
  const [dropdownValuePage,setDropdownValuePage] = useState('')

    const handleOpen = (title, message) => {
     console.log('handle open',title, message)
      setModalTitle(title);
      setModalMessage(message);
      setModalOpen(true);
    };
  
    const handleClose = () => {
      setModalOpen(false);
    };

    //////////////////////// remove from s3

    const removeFromS3 = (s3Key)=>{
      console.log(s3Key)
      axios.post(`${process.env.REACT_APP_URL}/removeFromS3`,{s3Key},{
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(res => { console.log('Removed from S3')}).catch(error => console.log('Failed to Remove From S3'))
    }
   
    const savePostId =(id)=>{
      const useremail = localStorage.getItem('useremail')
      const profile = localStorage.getItem('InstaProfile')
      const pageId = localStorage.getItem('InstaFbPage')
      fetch(`${process.env.REACT_APP_URL}/saveinstapostid`,
    
        {
          method: "POST",
          body: JSON.stringify({
            "email": useremail,
            "id": id,
            'profile':profile,
            'pageId':pageId
          }),
          headers: { "Content-type": "application/json" }
        })
        .then(response => response.json())
        .then(result => {
          console.log(result)
          if (result === "True") {
            // setLoading(false); // Hide loading indicator
            console.log("Post shared successfully!");
            // handlePostSuccess()
          } else if (result === "False") {
            console.log(result)
            // handleError()
          } else {
            // handleError()
            console.log(result)
          }
    
        })
        .catch(error => { console.log(error) })
    }

    useEffect(()=>{
      const useremail = localStorage.getItem('useremail')
      console.log("useremail", useremail)
  
      if (useremail !== null) {
  axios.post(`${process.env.REACT_APP_URL}/instaProfileList`, {useremail}, {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*'
          },
        }).then(res => {console.log(res.data);setInstaProfileList(res.data)}).catch(err => console.log(err));
      }
    },[])

    // ////////////////////////////////////////////////////////////////////// check if user is connected and print result
    const statusChangeCallback = async(response) => {
        console.log('statusChangeCallback', response);
        if (response.status === 'connected') {
          console.log('User is connected');
          await setUserAccessToken(response.authResponse.accessToken);

          await getPageAccessTokenAndId(userAccessToken, function(pageId, pageAccessToken) {
            console.log(`Page ID: ${pageId}`," i have commented out psot to page in handlePostToPage func");

          });

          // Perform actions based on the response.authResponse
        } else if (response.status === 'not_authorized') {
          console.log('User is logged into Facebook but not authenticated to your app');
        } else {
          console.log('User is not logged into Facebook');
        }
      };

      /////////////////////////////////////////////////////////////////////////////post to insta
      const publishMediaToInstagram = (userId, imageUrl, caption, s3Key) => {
        const accessToken = userAccessToken; // Replace with your access token
        console.log('User ID:', userId, 'Image URL:', imageUrl, 'Access Token:', accessToken, 'Caption:', caption, 's3Key:',s3Key);
      
        Swal.fire({
          title: "Are you sure?",
          text: "You want to Post",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes"
        }).then((result) => {
          if (result.isConfirmed) {
          // Step 1: Create Container
        fetch(`https://graph.facebook.com/v20.0/${userId}/media`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            image_url: imageUrl,
            caption: caption,
            access_token: accessToken,
          }),
        })
        .then(response => response.json())
        .then(containerData => {
          if (containerData.error) {
            console.log('4')
            setLoadingAnime(false)
            removeFromS3(s3Key)
            throw new Error(containerData.error.message);
          }
          const containerId = containerData.id;
          console.log('Container ID:', containerId);
      
          // Step 2: Publish Container
          return fetch(`https://graph.facebook.com/v20.0/${userId}/media_publish`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              creation_id: containerId,
              access_token: accessToken,
            }),
          });
        })
        .then(response => response.json())
        .then(mediaData => {
          if (mediaData.error) {
            console.log('1')
            setLoadingAnime(false)
            removeFromS3(s3Key)
            throw new Error(mediaData.error.message);
            
          }
          const mediaId = mediaData.id;
          savePostId(mediaId)
          console.log('Media ID:', mediaId,userId);
          console.log('Media published successfully!');
          Swal.fire({
            title: "Successful",
            text: "Post Shared Successfully.",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes"
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
    
          });
          toast.success('Posted To Instagram Successfully')
          setLoadingAnime(false)
        }).then(res => {removeFromS3(s3Key);console.log('2')})
        .catch(error => {
          console.error('Error:', error);
          setLoadingAnime(false)

          
          toast.error(error.message);
          removeFromS3(s3Key)
        });
          }else {
              setLoadingAnime(false)
              return
            }
        })
        
       

      };

      const handleImageUpload = (event) => {
        console.log('handle image upload',event.target.files[0])
        const file = event.target.files[0];
        setSelectedFile(file);
        if (file) {
          const imageUrl = URL.createObjectURL(file);
          setImageBlobSrc(imageUrl);
        }
      };
    
      const uploadImageToServer = async (file) => {
        const formData = new FormData();
        console.log(file,"---file")
        formData.append('image', file);
    
        try {
          const response = await axios.post(`${process.env.REACT_APP_URL}/uploadInsta`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          return response.data;
        } catch (error) {
          console.error('Error uploading image:', error);
          throw error;
        }
      };

      const handleInstaPost=async()=>{
        // console.log("insta post handle ",instaPageId, image, postText)
        if (selectedFile) {
         
            const imageData = await uploadImageToServer(selectedFile);
            const image = imageData.imageUrl
            const s3Key = imageData.s3Key
            
            publishMediaToInstagram(instaPageId, image , postText, s3Key)
            // publishMediaToInstagram(instaPageId, 'https://t4.ftcdn.net/jpg/06/10/26/15/360_F_610261529_vk9kf4ooTP5eSsQdOEyB4miRHn1YWCD1.jpg', 'Caption sent from api')
      }else{
        toast.error('Kindly Select an Image To Upload')
        setLoadingAnime(false)

      }
      }

   
      //////////////////////////////////////////////////////////////////////////////////////////// find and set page id
      const getPageAccessTokenAndId = (userAccessToken, callback) => {
        try {
          window.FB.api('v20.0/me/accounts', 'GET', { access_token: userAccessToken }, function(response) {
            if (response && !response.error) {
              console.log(response.data)
              // Check if there are pages available
              if (response.data && response.data.length > 0) {
                console.log(response.data[0],'account---',response.data)
                // Get the first page's ID and access token
                const page = response.data[0];
                // const pageId = response.data[0].id;
                setPageList(response.data)
                // Get the first page's ID and access token
              //   const pageId = page.id;
                setPageId(response.data[0].id)
                setPageName(response.data[0].name)
                setPageAccessToken(response.data[0].access_token)
              //   callback(pageId, pageAccessToken);
              } else {
                console.error('No pages found for the user.');
                handleOpen('No pages found for the user.', 'Kindly Create a Page from your Facebook profile')
                Cookies.remove('instaAccessToken');
                setPageId('')
                return
              }
            } else {
              console.error('Error fetching page access token:', response.error.message);
              
              if(response.error.code === 190){
                toast.error("Kindly Login, Your Session has Expired")
                Cookies.remove('instaAccessToken');
                handleInstaLogin()
              }
              // handleOpen('Kindly Login, Your Session has Expired','Kindly Login, Your Session has Expired')
              Cookies.remove('instaAccessToken');
              setPageId('')

              return
            }
            console.log(pageId,userAccessToken,"-----188 pageID, userAccessToken")
            getInstagramInfo(response.data[0].id,userAccessToken)
          });
        } catch (error) {
          console.log(error)
        }
        // Fetch pages managed by the user
      
      };

      ///////////////////////////////////////////////////////////////////// chk login state
      const checkLoginState = () => {
        window.FB.getLoginStatus(function(response) {
          statusChangeCallback(response);
        });
      };
    
         /////////////////////////////////////////////////////////// get insta page id
         const getInstagramInfo = (pageId,userAccessToken) => {
          console.log("Fetching Instagram Business Account info...");
         console.log('page id in insta func',pageId,"-- access--,",userAccessToken)
         localStorage.setItem('InstaFbPage',pageId)
          
          window.FB.api(`${pageId}?fields=instagram_business_account{id,name,username,profile_picture_url}`, 'GET', { access_token: userAccessToken }, function(response) {
            if (response && !response.error) {
              console.log(response);
              // Check if Instagram Business Account info is available
              if (response.instagram_business_account) {
                const instagramAccount = response.instagram_business_account;
                console.log('Instagram Business Account Info:', instagramAccount);
                setInstaPageId(instagramAccount.id)
      
                // You can now access the Instagram Business Account ID and other details
              } else {
                console.error('No Instagram Business Account found for the Page.');
                toast.error("No Instagram Business Account found for the Page.")
                handleOpen('No Instagram Business Account found for the Page.','Please create a Facebook Page and connect your Instagram Business account to it.')
                Cookies.remove('instaAccessToken');
                setPageId('')
              }
            } else {
              console.error('Error fetching Instagram Business Account info:', response.error);
            }
          });
        };
  
        const handleSaveInstaTokenDb = (token,profile) => {
          axios.post(`${process.env.REACT_APP_URL}/SaveInstaTokenDb`, {
            token,
            useremail,
            profile
          })
          .then(response => {
            console.log('Token saved successfully:', response);
          })
          .catch(error => {
            console.error('Error saving token:', error);
          });
        };

        const fetchFacebookUserProfile = (userId, accessToken) => {
          return new Promise((resolve, reject) => {
            window.FB.api(
              `/${userId}`,
              'GET',
              { fields: 'id,name', access_token: accessToken },
              function(response) {
                if (response && !response.error) {
                  resolve(response);
                } else {
                  reject(response.error);
                }
              }
            );
          });
        };
        

      ////////////////////////////////////////////////////////////// FB LOGIN
      const handleInstaLogin = () => {
        return new Promise((resolve, reject) => {
          window.FB.login(response => {
            if (response.status === 'connected') {
              resolve(response);
            } else {
              reject(new Error('User not authenticated'));
            }
          }, {
            scope: 'public_profile, pages_manage_posts, pages_read_engagement, pages_show_list',
            return_scopes: true
          });
        })
        .then(response => {
          const shortLivedToken = response.authResponse.accessToken;
          const userId = response.authResponse.userID;
      
          setUserId(userId);
      
          return fetch(`${process.env.REACT_APP_URL}/exchange-token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ token: shortLivedToken })
          })
          .then(response => response.json())
          .then(data => {
            if (data.longLivedToken) {
              const longLivedToken = data.longLivedToken;
      
              // Save access token in cookies
              Cookies.set('instaAccessToken', longLivedToken);
              localStorage.setItem('InstaToken',longLivedToken)
      
              // Check login state and save token to DB
              checkLoginState();
      

              return fetchFacebookUserProfile(userId, longLivedToken)
          .then(profileData => {
            // Save profile data in cookies and DB
            Cookies.set('instaProfile', profileData.name);
            localStorage.setItem('InstaProfile',profileData.name)
            handleSaveInstaTokenDb(longLivedToken,profileData.name);
            return { userId, longLivedToken, profileData };
          });

              // Save token to DB
              // handleSaveInstaTokenDb(longLivedToken);
            } else {
              throw new Error('Failed to exchange token');
            }
          });
        })
        .catch(error => {
          console.error('Error during Instagram login or token exchange:', error);
          // Remove cookies and local storage items on error
          // removeItems();
        });
      };

      const handlePostShare=()=>{
        setLoadingAnime(true)
        console.log('handl post to share')
        handleInstaPost()
      }

      // //////////////////////////////////////////////// LOAD FB SDK
  
      useEffect(() => {
        const loadFbSdk = () => {
          if (!window.FB) {
            window.fbAsyncInit = function() {
              window.FB.init({
                appId: FB_APP_ID, // Ensure this is your correct app ID
                cookie: true,
                xfbml: true,
                version: 'v20.0'
              });
    
              window.FB.AppEvents.logPageView();
    
              // Render the login button
              window.FB.XFBML.parse();
              setSdkLoaded(true); // SDK has loaded
            };
    
            (function(d, s, id){
              var js, fjs = d.getElementsByTagName(s)[0];
              if (d.getElementById(id)) {return;}
              js = d.createElement(s); js.id = id;
              js.src = "https://connect.facebook.net/en_US/sdk.js";
              fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
          } else {
            window.FB.XFBML.parse();
            setSdkLoaded(true); // SDK is already loaded
          }
        };
    
        loadFbSdk();
      }, []);
    
      useEffect(() => {
        if (sdkLoaded && !Cookies.get('instaAccessToken')) {
          handleInstaLogin();
        }else if(sdkLoaded && Cookies.get('instaAccessToken')){
          setUserAccessToken(Cookies.get('instaAccessToken'));

          getPageAccessTokenAndId(Cookies.get('instaAccessToken'), function(pageId, pageAccessToken) {
            console.log(`Page ID: ${pageId}`," i have commented out psot to page in handlePostToPage func");
          });

        }
      }, [sdkLoaded]);

      const handleDropdownChange = (event) => {
        setDropdownValue(event.target.value);
        // localStorage.setItem('FbProfile',event.target.value)
        const selectedProfile = instaProfileList.find(profile => profile.InstaProfile === event.target.value);
    // @
      if (selectedProfile) {
        // Do something with the selected profile
        console.log('Selected Profile:', selectedProfile);
        localStorage.setItem('InstaProfile',selectedProfile.InstaProfile)
        localStorage.setItem('InstaToken',selectedProfile.InstaToken)
        Cookies.set('instaAccessToken', selectedProfile.InstaToken);
        getPageAccessTokenAndId(selectedProfile.InstaToken, function(pageId, pageAccessToken) {
          console.log(`Page ID: ${pageId}`);
        });
    
      } else {
        console.log('Page not found in Page List');
      }
      };

      const handleDropdownChangePage = (event) => {
        setDropdownValuePage(event.target.value);
        // localStorage.setItem('FbProfile',event.target.value)
        const selectedProfile = pageList.find(profile => profile.name === event.target.value);
    
      if (selectedProfile) {
        // Do something with the selected profile
        console.log('Selected Profile:', selectedProfile);
        setPageName(selectedProfile.name)
        setPageId(selectedProfile.id)
        setPageAccessToken(selectedProfile.access_token)
        getInstagramInfo(selectedProfile.id,localStorage.getItem('InstaToken'))
    
      } else {
        console.log('Page not found in Page List');
      }
      };

      const handleFbLogout=()=>{
        console.log('LOgout')
        localStorage.removeItem('instaToken');
localStorage.removeItem('instaProfile');
// Remove cookies (assuming you have a function to remove cookies)
Cookies.remove('instaAccessToken');
Cookies.remove('instaProfile');
window.location.reload()
      }

      const paperStyle = {  minHeight: "90%", width: "50%", margin: "5px", minWidth:'300px',marginTop:'5px' }


  return (
    <div style={{backgroundColor:'whitesmoke'}}>
          {/* <button onClick={handleInstaLogin}>Log in</button> */}
          {/* <button onClick={handleInstaPost}>Post on Insta</button> */}
    InstaLoginPosting
    
    <div>
        {/* FBloginPosting */}
         {/* <button onClick={handleFBLogin}>Log in with Facebook</button> */}
         {/* <button value='Sentence to post on fb' onClick={message=>postToPage(message.target.value)}>Post on Facebook Facebook</button> */}
         <Box sx={{ display: 'flex', minHeight: "100vh", height:'100%' }}>
      <Header />
      <CustomModal
        open={modalOpen}
        handleClose={handleClose}
        title={modalTitle}
        message={modalMessage}
      />

      <Box width="95%" minHeight='107vh'   m="5px" marginTop="80px" display="flex" justifyContent="center"    alignItems='center'
      flexDirection='column'
        sx={{
          // backgroundImage: 'repeating-radial-gradient(circle at 0 0, rgba(107, 70, 255, .59), #FFF9D0 39%, #03AED2)'

        }}>

<Paper
          elevation={1}
          style={{ padding: 10, height: "80px", width: "50%", marginTop: "30px",minWidth:'300px' }}
          sx={{ borderRadius: "10px", bgcolor: "white", display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}
        >
          <Box
            borderRadius="10px"
            bgcolor="#007F73"
            width="70%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={0}
            mx={1}
          >
            <Typography
              fontSize="25px"
              fontWeight="100px"
              color="white"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <InstagramIcon sx={{ fontSize: "28px", mr: 2 }} />
              @{localStorage.getItem('InstaProfile')}
            </Typography>
          </Box>
          <FormControl 
           borderRadius="10px"
        sx={{ mt: 0, width: '20%', mx: 1 }}
      >
        <InputLabel id="dropdown-label" sx={{ bgcolor: "#007F73", color: "white", pl: 1, pr: 1, borderRadius: 1 }}>Profiles</InputLabel>
        <Select
          labelId="dropdown-label"
          id="dropdown"
          value={dropdownValue}
          onChange={handleDropdownChange}
          label="Profiles"
          sx={{  height:'38px',bgcolor: "#007F73", color: "white", '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
        >
          {
            instaProfileList.map(x =>
              <MenuItem value={x.InstaProfile}>{x.InstaProfile}</MenuItem>
            )
          }
              <MenuItem onClick={()=>handleFbLogout()} >Log Out</MenuItem>
          
        </Select>
      </FormControl>
        </Paper>

<Paper elevation={3} style={paperStyle} sx={{ borderRadius: '10px', bgcolor: 'white', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <Box borderRadius="10px" bgcolor="#007F73" width="90%" display="flex" justifyContent="center" alignItems="center" mt={1}>
        <Typography fontSize="25px" fontWeight="100px" color="white" sx={{ display: 'flex', alignItems: 'center' }}>
          <InstagramIcon sx={{ fontSize: "28px", mr: 2 }} />
          Create Your Post
        </Typography>
      </Box>
      <Box
          borderRadius="10px"
          bgcolor="#007F73"
          width="90%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={3}
        >
          <Box
            borderRadius="10px"
            bgcolor="#007F73"
            width="70%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={0}
            mx={1}
          >
            <Typography
              fontSize="25px"
              fontWeight="100px"
              color="white"
              sx={{ display: "flex", alignItems: "center" }}
            >
              FB Page - {pageName}
            </Typography>
          </Box>
          <FormControl 
  borderRadius="10px"
  sx={{ mt: 0, width: '20%', mx: 1 }}
>
  <InputLabel id="dropdown-label" sx={{ bgcolor: "#007F73", color: "white", pl: 1, pr: 1, borderRadius: 1 }}>Pages</InputLabel>
  <Select
    labelId="dropdown-label"
    id="dropdown"
    value={dropdownValuePage}
    onChange={handleDropdownChangePage}
    label="Pages"
    style={{color:'white'}}
    sx={{ height: '38px', bgcolor: "#007F73", color: "white", '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
    MenuProps={{
      PaperProps: {
        sx: {
          bgcolor: "#007F73",
          '& .MuiMenuItem-root': {
            color: 'white',
          },
          '& .MuiMenuItem-root.Mui-selected': {
            bgcolor: '#005F56', // Change to a darker shade or another color for selected item background
            color: 'white',    // Ensures selected item text color is white
          },
        },
      },
    }}
  >
    {
      pageList.map(x =>
        <MenuItem key={x.name} value={x.name} sx={{ color: 'white' }}>
          {x.name}
        </MenuItem>
      )
    }
  </Select>
</FormControl>


        </Box>

      {
        pageId === '' ? (
          <Button sx={{ mt: 5, height: '50px', width: '150px', mb: 10 }} color='success' variant="contained" onClick={handleInstaLogin} startIcon={<InstagramIcon />}>Login To Instagram</Button>
        ) : (
          <>
           <TextField
              id="standard-multiline-static"
              label="Write your Instagram Post here"
              multiline
              rows={2}
              variant="standard"
              sx={{ width: '90%', m: '25px' }}
              value={postText}
              onChange={(e) => {
                setPostText(e.target.value);
                setEdited(true);
              }}
            />
            <label htmlFor="upload-image">
              <Button variant="contained" component="span" sx={{  }} style={{display:'flex', justifyContent:'center', marginTop:'20px'}}>
                Upload Image
              </Button>
            </label>
            {imageBlobSrc !== '' && (
              <Box sx={{ width: '90%', m: '25px' }}>
                <Typography variant="body1" color="textSecondary">
                  {/* {image.name} */}
                </Typography>
                <img src={imageBlobSrc} alt="Preview" style={{ width: '100%', maxHeight: '250px', objectFit: 'contain' }} />
              </Box>
            )}
             {imageBlobSrc === '' && (
              <Box sx={{ width: '90%', m: '25px' }}>
              <Typography variant="body1" color="textSecondary">
                {/* {image.name} */}
              </Typography>
              <img src={DummyProfile} alt="Preview" style={{ width: '100%', maxHeight: '250px', objectFit: 'contain' }} />
            </Box>
            )}
            <input
              accept="image/*"
              id="upload-image"
              type="file"
              style={{ display: 'none' }}
              onChange={handleImageUpload}
            />
           
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button sx={{ mt: 5, height: '30px', width: '100px', mb: 10 }} color='success' variant="contained" onClick={handlePostShare} startIcon={<PostAddIcon />}>Post</Button>
            </Box>
            {
                loadingAnime?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20px' }}>
                <CircularProgress />
              </div>:''
              }
          </>
        )
      }
    </Paper>
  
      </Box>

    </Box>
    <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />
    </div>

    </div>
  )
}

export default InstaLoginPosting