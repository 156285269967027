import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import favicon from '../Images/favicon.ico';

const pages = ['Home', 'Products', 'Blogs', 'Contact Us', 'About Us'];

function HomeTopbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const menuItems = (
    <>
    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center" justifyContent={isMobile ? 'flex-start' : 'space-evenly'} width="100%">
      {pages.map((page) => (
        <Tooltip title={page} key={page}>
          <Button
            type="submit"
            fullWidth
            sx={{ m: 1, color: "black", fontWeight: 'bold', fontSize: "13px", width:'130px',marginLeft:'0px',marginRight:'0px',paddingLeft:'0px',paddingRight:'0px' }}
            color='secondary'
          >
            {page}
          </Button>
        </Tooltip>
      ))}
    </Box>
    <Box mr={isMobile ? 0 : 10}>
    <Tooltip title="Sign In">
      <Button
        type="submit"
        fullWidth
        sx={{
          m: 1,
          color: "#0741AD",
          fontSize: "13px",
          backgroundColor: "transparent",
          borderRadius: 8,
          height: 45,
          fontWeight: 600,
          border: "1px solid #0741AD"
        }}
        onClick={() => localStorage.clear()}
        startIcon={<LoginIcon />}
        href='/signin'
      >
        Log In
      </Button>
    </Tooltip>
  </Box>
  </>
  );

  return (
    <AppBar position="fixed" sx={{ bgcolor: 'white' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Box display="flex" alignItems="center" justifyContent="space-evenly" ml={isMobile ? 0 : 10}>
              <img
                src={favicon}
                alt=""
                style={{ marginRight: '1rem', height: '3rem' }}
              />
              <Typography variant="h6" component="h2">
                ShadowBox
              </Typography>
            </Box>
            {isMobile ? (
              <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
            ) : (
              <Box display="flex" alignItems="center" justifyContent="space-evenly" width="550px" style={{paddingRight:'100px'}}>
                {menuItems}
              </Box>
            )}
            
          </Box>
        </Toolbar>
      </Container>
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
        <Box
          sx={{ width: 250, padding: 2 }}
          role="presentation"
          onClick={handleDrawerToggle}
          onKeyDown={handleDrawerToggle}
        >
          {menuItems}
        </Box>
      </Drawer>
    </AppBar>
  );
}

export default HomeTopbar;
