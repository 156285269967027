import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { Paper } from '@mui/material';
import { Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CheckIcon from '@mui/icons-material/Check';
import InputAdornment from '@mui/material/InputAdornment';
import { Navigate, useNavigate } from 'react-router-dom';
import favicon from '../../Images/favicon.ico'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'







export const Signup = () => {
    const [useremail, setEmail] = useState('');
    const [name, setName] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [showOtpField, setShowOtpField] = useState(false);
    const [otpValue, setOtpValue] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [otpTimestamp, setOtpTimestamp] = useState(0);
    const [otpVerified, setOtpVerified] = useState(false);
    const [resendOtp, setResendOtp] = useState(false);
    const [verifyOtpDisabled, setVerifyOtpDisabled] = useState(false);
    const [resendOtpDisabled, setResendOtpDisabled] = useState(false);
    const [showBackdrop, setShowBackdrop] = useState(false);
 


    const navigate = useNavigate()




    const handleOtpChange = (event) => {
        setOtpValue(event.target.value);
    };


    const handleError = () => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        }).then((result) => {
          if (result.isConfirmed) {
            // Reload the window after clicking "OK"
            window.location.reload();
          }
        });
      }

    const handleEmailError = () => {
        Swal.fire({
            title: "Error",
            text: "Please fill all fields",
            icon: "warning",
            showCancelButton: false, // Set to false to hide the cancel button
            showConfirmButton: true, // Set to true to show the confirm (OK) button
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK" // Change the text of the confirm button to "OK"
          }).then((result) => {
            if (result.isConfirmed) {
              console.log("email error");
              
            }
          });
    }

    
    const handleWrongEmailError = () => {
        Swal.fire({
            title: "Error",
            text: "Email not found",
            icon: "warning",
            showCancelButton: false, // Set to false to hide the cancel button
            showConfirmButton: true, // Set to true to show the confirm (OK) button
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK" // Change the text of the confirm button to "OK"
          }).then((result) => {
            if (result.isConfirmed) {
              console.log("email error");
              window.location.reload();
            }
          });
    }

    const handleCode = () => {
        Swal.fire({
            title: "successful.",
            text: "Verification code send successfully.",
            icon: "success",
            showCancelButton: false, // Set to false to hide the cancel button
            showConfirmButton: true, // Set to true to show the confirm (OK) button
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK" ,// Change the text of the confirm button to "OK"
          }).then((result) => {
            if (result.isConfirmed) {
              console.log("email error");
            }
          });
    }

    const handleCodeExpire = () => {
        Swal.fire({
            title: "Code Expired",
            text: "Please click on resend",
            icon: "warning",
            showCancelButton: false, // Set to false to hide the cancel button
            showConfirmButton: true, // Set to true to show the confirm (OK) button
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK" // Change the text of the confirm button to "OK"
          }).then((result) => {
            if (result.isConfirmed) {
              console.log("email error");
            }
          });
    }

    const handleCodeVerify = () => {
        Swal.fire({
            title: "Successfull.",
            text: "Code verify successfully.",
            icon: "success",
            showCancelButton: false, // Set to false to hide the cancel button
            showConfirmButton: true, // Set to true to show the confirm (OK) button
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK" // Change the text of the confirm button to "OK"
          }).then((result) => {
            if (result.isConfirmed) {
              console.log("email error");
            }
          });
    }


    const handleWrongCode = () => {
        Swal.fire({
            title: "Error",
            text: "Enter Valid Code",
            icon: "warning",
            showCancelButton: false, // Set to false to hide the cancel button
            showConfirmButton: true, // Set to true to show the confirm (OK) button
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK" // Change the text of the confirm button to "OK"
          }).then((result) => {
            if (result.isConfirmed) {
              console.log("email error");
            }
          });
    }


    const handleNoCode = () => {
        Swal.fire({
            title: "Error",
            text: "Enter Verification Code",
            icon: "warning",
            showCancelButton: false, // Set to false to hide the cancel button
            showConfirmButton: true, // Set to true to show the confirm (OK) button
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK" // Change the text of the confirm button to "OK"
          }).then((result) => {
            if (result.isConfirmed) {
              console.log("email error");
            }
          });
    }

    const handleExist = () => {
        Swal.fire({
            title: "User Exist",
            text: "Please Sign In",
            icon: "warning",
            showCancelButton: false, // Set to false to hide the cancel button
            showConfirmButton: true, // Set to true to show the confirm (OK) button
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK" // Change the text of the confirm button to "OK"
          }).then((result) => {
            if (result.isConfirmed) {
              console.log("email error");
            }
          });
    }

    // const handleCheckEmail = async () => {
    //     setShowOtpField(true);
    //     setResendOtp(true); // Set resend OTP flag
    //     console.log(useremail);
    //     try {
    //         const response = await fetch(`${process.env.REACT_APP_URL}/verifyemail`, {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify({ email: useremail }),
    //         });
    //         const result = await response.json();
    //         if (!useremail) {
    //             alert("Enter Email");
    //         } else if (result === "False") {
    //             alert("Email Id Not found!!!");
    //         } else if (result.otp) {
    //             localStorage.setItem('userotp', result.otp);
    //             alert("OTP sent successfully!!!!");
    //             setOtpSent(true);
    //             setOtpTimestamp(Date.now());
    //         } else {
    //             alert("Unknown Error!!!! Please Try again");
    //         }
    //     } catch (error) {
    //         console.error("Error To send OTP:", error);
          
           
           
    //     }
    // };




    const handleCheckEmail = async () => {
        setShowBackdrop(true); // Show the backdrop
        setShowOtpField(true);
        setResendOtp(true); // Set resend OTP flag
        console.log(useremail);
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/verifyemail`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: useremail }),
            });
            const result = await response.json();
            if (!useremail) {
                handleEmailError()
            } else if (result === "False") {
                handleWrongEmailError()
            } else if (result.otp) {
                localStorage.setItem('userotp', result.otp);
                handleCode();
                setOtpSent(true);
                setOtpTimestamp(Date.now());
            } else {
               handleError()
            }
        } catch (error) {
            console.error("Error To send OTP:", error);
           handleError()
            
        } finally {
            setShowBackdrop(false); // Hide the backdrop
            
        }
    };



    const handleSignup = () => {
        if (!name || !useremail || !otpValue) {
           handleEmailError()
            return;
        } else {
            // Navigate('/setpassword')

            console.log("seccessfulllllll");
            localStorage.setItem('useremail', useremail)
            localStorage.setItem('userName', name)
            localStorage.setItem('userContact', contactNo)
            loginCheck();
        }
    };

    const handleSubmitOtp = (event) => {
        event.preventDefault(); // Prevent default form submission behavior

        const userOtp = localStorage.getItem('userotp');
        const currentTime = Date.now();
        const otpValidityDuration = 5 * 60 * 1000;

        if (currentTime - otpTimestamp > otpValidityDuration) {
            handleCodeExpire()
        } else if (otpValue === userOtp) {
            handleCodeVerify()
            setOtpVerified(true); // Set OTP verification status
            setVerifyOtpDisabled(true); // Disable Verify OTP button
            setResendOtpDisabled(true); // Disable Resend OTP button
        } else if (!otpValue) {
            handleNoCode()
        } else {
            handleWrongCode()
        }
    };



    const loginCheck = async () => {
        try {
            const useremail = localStorage.getItem('useremail')
            console.log(useremail)
            const response = await fetch(`${process.env.REACT_APP_URL}/logincheck`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: useremail }),
            }).then(response => response.json())
                .then(result => {
                    console.log(result)
                    if (result === "Signup") {
                        console.log("User already present!!!!!!");
                        handleExist()
                        navigate("/signin")
                        //localStorage.clear();

                    } else if (result === "False") {

                        navigate("/setpassword")

                    } else {
                        console.log("Error in login")
                        handleError()
                    }

                });


        } catch (error) {
            console.error("Error during login:", error);
            handleError()
            navigate("/")
        }


    }

    return (
        <ThemeProvider theme={createTheme()}>

            <Box sx={{ backgroundImage: 'repeating-radial-gradient(circle at 0 0, rgba(107, 70, 255, .59), #14141f 50%, #14141f)', height: '100%', position: 'relative' }}>
                {/* <Container component="main" maxWidth="xs" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> */}
                <Container component="main" maxWidth="xs" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                    <CssBaseline />
                    {showBackdrop && (
                        <div className="backdrop">
                            <LinearProgress color="success" />
                        </div>
                    )}


                    <Paper elevation={10} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3, borderRadius: "25px" }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: "25px 25px 0 0", width: "100%" }}>
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} src={favicon}>

                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Sign Up
                            </Typography>
                        </Box>
                        <Box component="form" noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Full Name"
                                name="name"
                                autoComplete="name"
                                autoFocus
                                onChange={(e) => setName(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <PersonIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="LinkedIn Email Address"
                                name="email"
                                autoComplete="email"
                                onChange={(e) => setEmail(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {otpVerified ? <CheckIcon sx={{ color: 'green' }} /> : <EmailIcon />}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {showOtpField && (
                                <form noValidate>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="otp"
                                        label="Enter Verification Code"
                                        name="otp"
                                        autoComplete="off"
                                        autoFocus
                                        value={otpValue}
                                        onChange={handleOtpChange}
                                    />
                                </form>
                            )}
                            <Button
                                type="button"
                                variant="contained"
                                size='small'
                                sx={{ mt: 1 }}
                                color={resendOtp ? 'primary' : 'primary'}
                                onClick={handleCheckEmail}
                                disabled={resendOtpDisabled}
                            >
                                {resendOtp ? 'Resend Code' : 'Send Code'}
                            </Button>
                            <Button
                                type="submit"
                                fullWidth
                                size='small'
                                variant="contained"
                                sx={{ mt: 2, mb: 2 }}
                                onClick={handleSubmitOtp}
                                disabled={!showOtpField}
                            >
                                Verify Code
                            </Button>
                            <TextField
                                margin="normal"
                                fullWidth
                                id="contactNo"
                                label="Contact Number (Optional)"
                                name="contactNo"
                                autoComplete="tel"
                                onChange={(e) => setContactNo(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <PhoneIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 2, mb: 2 }}
                                onClick={handleSignup}
                                disabled={!otpVerified}
                            >
                                Sign Up
                            </Button>
                        </Box>
                    </Paper>
                </Container>
            </Box>
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />
        </ThemeProvider>
    );
};

export default Signup;