import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Topbar from './Topbar';
import BoxCard from './BoxCard';
import BarDiagram from './BarDiagram';
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import BasicPie from './BasicPie';
import { Paper, Typography, colors } from '@mui/material';
import { Header } from './Header';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { useEffect } from 'react';
import { useState } from 'react';
import { Card, CardContent, CardActions, Button, CardMedia } from '@mui/material';
import Swal from 'sweetalert2'


export const MiniDrawer = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [twitterCount, setTwitterCount] = useState(0);
  const [linkedinCount, setLinkedinCount] = useState(0);
  const [showMessage, setShowMessage] = useState(false);










  //const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);


  const userCount = localStorage.getItem('userCount')
  console.log("^^^^^^^^^^^^^^^66count :" , userCount);


  const totalPost = () => {
    const email = localStorage.getItem('useremail')
    fetch(`${process.env.REACT_APP_URL}/totalposts`, {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({ email : email })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const { twitterPostCount, linkedinPostCount } = data;
        setTwitterCount(twitterPostCount);
        setLinkedinCount(linkedinPostCount);
        console.log(twitterPostCount , linkedinPostCount);
      })
      .catch(error => {
        handleError()
      });
  };

  useEffect(() => {
    totalPost();
  }, []);


  const handleError = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the window after clicking "OK"
        window.location.reload();
      }
    });
  }


  return (
    <Box sx={{ display: 'flex'}}>

      <Header />

      <Box component="main" sx={{ flexGrow: 1, p: 3  , backgroundImage: 'repeating-radial-gradient(circle at 0 0, rgba(107, 70, 255, .59), #FFF9D0 39%, #03AED2)'}} marginTop="10px">
        {/* <DrawerHeader /> */}

        <Box pt={5}>
          <BoxCard />
        </Box>



        <Box display="flex" justifyContent="space-evenly" alignItems="center" m="30px">
          <Paper elevation={15} >
            {/* <Box m={5}  > */}
              <BarDiagram />
            {/* </Box> */}
          </Paper>

          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
          
             <Box sx={{ width: '80%', height: '80%', display: 'flex', alignItems: 'center', justifyContent: 'center'  , mt:5}}>
              {/* <Card style={{ width: '80%', height: '80%' }}>
                <CardMedia
                  style={{ paddingTop: '56.25%' }}
                  image="https://static.vecteezy.com/system/resources/thumbnails/036/101/650/small_2x/ai-generated-blank-world-map-professionalgraphy-photo.jpg"
                  title="Image title"
                />
                <CardContent sx={{bgcolor:"#78A083"}}>
                  
                    <Typography variant="h5" component="h2" style={{ fontSize: '20px', fontWeight: 'bold', color: '#704264' }}>
                      Accounts Login to application -- {userCount}
                    </Typography>
                  
                </CardContent>
              </Card> */}
              
            </Box> 

            {/* </Paper> */}
            <Paper elevation={10} sx={{ m: 2, paddingBottom:2 }}>
              <Typography sx={{ fontSize: "20px", padding: 2, color: "green", display: "flex", alignItems: "center" }} >
                Your Posts
              </Typography>
              <BasicPie />
            </Paper>

          </Box>
          {/* 
          <Paper elevation={15} style={{margin:"20px"}}>
          
          </Paper> */}


        </Box>





      </Box>
      {/* <BottomNavi/> */}
    </Box>
  );
}

export default MiniDrawer;