import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ShareIcon from '@mui/icons-material/Share';
import InfoIcon from '@mui/icons-material/Info';

export const  BottomNavi = () =>{

  return (
    <Box marginTop="100%" display="flex" justifyContent="space-evenly" alignItems="end">
      <BottomNavigation 
      showLabels>
        <BottomNavigationAction label="Contact Us" color='white' icon={<ContactPageIcon/>} />
        <BottomNavigationAction label="Share" icon={<ShareIcon/>} />
        <BottomNavigationAction label="About US" icon={<InfoIcon />} />
      </BottomNavigation>
    </Box>


    
  );
}

export default BottomNavi;