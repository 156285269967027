import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import SearchTable from './SearchPeople';
import { Header } from './Header';




export const Search = () => {

  console.log("In Connections")
    console.log(localStorage.getItem('accessToken'));
    console.log(localStorage.getItem('userInfo'))
  const theme = useTheme();
  

  

  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  return (
    <Box sx={{ display: 'flex' }}>
     
      <Header/>
      
      <Box component="main" sx={{ flexGrow: 1, p: 3 }} marginTop="50px">
        
        <SearchTable/>


      </Box>
      
    </Box>



  );
}

export default Search;
