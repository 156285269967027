import React,{useState,useEffect} from 'react'
import Box from '@mui/material/Box'
import axios from 'axios'
import { Button, Paper, TextField, Typography, colors } from '@mui/material';
import { Header } from '../Header'
import PostAddIcon from '@mui/icons-material/PostAdd';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import Cookies from 'js-cookie';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CustomModal from '../CustomModal';
import CircularProgress from '@mui/material/CircularProgress';
import Swal from 'sweetalert2'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import DummyProfile from '../../../Images/DummyProfile2.jpg'


const FBloginPosting = () => {

  // const FB_APP_ID = process.env.REACT_APP_FB_APP_ID
  const FB_APP_ID ='1136139144384934'
  const useremail = localStorage.getItem('useremail')

  console.log(useremail,"--useremail")

  useEffect(()=>{
  if(localStorage.getItem('FbToken')){
    console.log(localStorage.getItem('FbToken'),"--------33")
    Cookies.set('fbAccessToken', localStorage.getItem('FbToken'));
  }
  },[])

  useEffect(()=>{
    const useremail = localStorage.getItem('useremail')

    if (useremail === null) {
      Swal.fire({
        title: "Login Required",
        text: "You need to log in to Shadowbox to use this functionality.",
        icon: "warning", // "fail" should be "warning" or "error"
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Login"
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = '/signin'; // Correctly set the window location
        }
      });
    }
    
  },[])

  const [userAccessToken,setUserAccessToken] = useState('')
  const [pageId,setPageId] = useState('')
  const [pageAccessToken,setPageAccessToken]= useState('')
  const [postText, setPostText] = useState('');
  const [edited, setEdited] = useState(false);
  const [sdkLoaded, setSdkLoaded] = useState(false);
  const [imageBlobSrc,setImageBlobSrc]=useState('')
  const [selectedFile, setSelectedFile] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [loadingAnime,setLoadingAnime] = useState(false)
  const [dropdownValue,setDropdownValue] = useState('')
  const [dropdownValuePage,setDropdownValuePage] = useState('')
  const [fbProfileList,setFbProfileList] = useState([])

  const [pageList,setPageList] = useState([])
  const [pageName,setPageName] = useState('')


  const handleOpen = (title, message) => {
    setModalTitle(title);
    setModalMessage(message);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };


  useEffect(()=>{
    const useremail = localStorage.getItem('useremail')
    console.log("useremail", useremail)

    if (useremail !== null) {
axios.post(`${process.env.REACT_APP_URL}/fbProfileList`, {useremail}, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*'
        },
      }).then(res => {console.log(res.data);setFbProfileList(res.data)}).catch(err => console.log(err));
    }
  },[])
  

      //////////////////////// remove from s3

      const removeFromS3 = (s3Key)=>{
        console.log(s3Key)
        axios.post(`${process.env.REACT_APP_URL}/removeFromS3`,{s3Key},{
          headers: {
            'Content-Type': 'application/json',
          },
        }).then(res => { console.log('Removed from S3')}).catch(error => console.log('Failed to Remove From S3'))
      }


//////////////////////////////////////////////////////////////////////////////////////////// find and set page id
      const getPageAccessTokenAndId = (userAccessToken, callback) => {
        console.log(userAccessToken,"----in getPageAccessTokenAndId ")
        // Fetch pages managed by the user
        window.FB.api('v20.0/me/accounts', 'GET', { access_token: userAccessToken }, function(response) {
          if (response && !response.error) {
            console.log(response.data)
            // Check if there are pages available
            if (response.data && response.data.length > 0) {
              console.log(response.data[0],'account---',response.data)
              setPageList(response.data)
              // Get the first page's ID and access token
              const page = response.data[0];
            //   const pageId = page.id;
              setPageId(response.data[0].id)
              setPageName(response.data[0].name)
              setPageAccessToken(response.data[0].access_token)
            //   const pageAccessToken = page.access_token;
            //   callback(pageId, pageAccessToken);
            } else {
              console.error('No pages found for the user.');
              handleOpen('No pages found for the user.', 'Kindly Create a Page from your Facebook profile')
              Cookies.remove('fbAccessToken');

                toast.error('No Page Connected to your FB profile.')
            }
          } else {
            console.error('Error fetching page access token:', response.error);
            console.log(response.error.code)
            if(response.error.code === 190){
            toast.error("Kindly Login, Your Session has Expired")

              Cookies.remove('fbAccessToken');
              handleFBLogin()
            }

          }
        });
        console.log(pageId)
      };

// ////////////////////////////////////////////////////////////////////// check if user is connected and print result
      const statusChangeCallback = async(response) => {
        console.log('statusChangeCallback', response);
        if (response.status === 'connected') {
          console.log('User is connected',response);
          await setUserAccessToken(response.authResponse.accessToken);

          await getPageAccessTokenAndId(response.authResponse.accessToken, function(pageId, pageAccessToken) {
            console.log(`Page ID: ${pageId}`);
          });

          // Perform actions based on the response.authResponse
        } else if (response.status === 'not_authorized') {
          console.log('User is logged into Facebook but not authenticated to your app');
        } else {
          console.log('User is not logged into Facebook');
        }
      };

      ///////////////////////////////////////////////////////////////////// chk login state
      const checkLoginState = () => {
        window.FB.getLoginStatus(function(response) {
            console.log(response)
          statusChangeCallback(response);
        });
      };
    

      const handleSaveFbTokenDb = (token,profile) => {
        axios.post(`${process.env.REACT_APP_URL}/SaveFbTokenDb`, {
          token,
          useremail,
          profile
        })
        .then(response => {
          console.log('Token saved successfully:', response);
        })
        .catch(error => {
          console.error('Error saving token:', error);
        });
      };


    // const handleFBLogin=()=>{
       
    //     return new Promise((resolve) => {
    //         window.FB.login(resolve, {
    //           scope: 'public_profile, pages_manage_posts, pages_read_engagement, pages_show_list',
    //           return_scopes: true
    //         });
    //       }).then(response => {
    //         if (response.status === 'connected') {
    //           let userId = response.authResponse.userID;
    //           console.log(response,"-------------handle FB LOGIN")
    //           Cookies.set('fbAccessToken', response.authResponse.accessToken);
    //           checkLoginState();
    //           handleSaveFbTokenDb(response.authResponse.accessToken)

    //         } else if (response.status === 'connected' ) {
    //         }
    //       });
    // }

    

      //////DONN"T DELETR///////////////DONN"T DELETR////////////DONN"T DELETR//////////////////// post to fb in page original DONN"T DELETR
//       const postToPage = (message) => {
//  console.log(pageId, pageAccessToken, message)
//         // Post a message to the page feed
//         window.FB.api(
//           `/${pageId}/feed`,
//           'POST',
//           {
//             message: message,
//             access_token: pageAccessToken
//           },
//           function(response) {
//             if (response && !response.error) {
//               console.log('Post ID: ' + response.id);
//             } else {
//               console.error('Error posting to page:', response.error);
//               toast.error(response.error.message)
//             }
//           }
//         );
//       };

const savePostId =(id,pageId)=>{
  const useremail = localStorage.getItem('useremail')
  const profile = localStorage.getItem('FbProfile')
  fetch(`${process.env.REACT_APP_URL}/savefbpostid`,

    {
      method: "POST",
      body: JSON.stringify({
        "email": useremail,
        "id": id,
        'profile':profile,
        'pageId':pageId
      }),
      headers: { "Content-type": "application/json" }
    })
    .then(response => response.json())
    .then(result => {
      console.log(result)
      if (result === "True") {
        // setLoading(false); // Hide loading indicator
        console.log("Post shared successfully!");
        // handlePostSuccess()
      } else if (result === "False") {
        console.log(result)
        // handleError()
      } else {
        // handleError()
        console.log(result)
      }

    })
    .catch(error => { console.log(error) })
}


const handleFBLogin = () => {
  return new Promise((resolve, reject) => {
    window.FB.login(response => {
      if (response.status === 'connected') {
        resolve(response);
      } else {
        reject(new Error('User not authenticated'));
      }
    }, {
      scope: 'public_profile, pages_manage_posts, pages_read_engagement, pages_show_list',
      return_scopes: true
    });
  })
  .then(response => {
    const shortLivedToken = response.authResponse.accessToken;
    const userId = response.authResponse.userID;

    console.log(response, "-------------handle FB LOGIN");

    return fetch(`${process.env.REACT_APP_URL}/exchange-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token: shortLivedToken })
    })
    .then(response => response.json())
    .then(data => {
      if (data.longLivedToken) {
        const longLivedToken = data.longLivedToken;

        // Save access token in cookies
        Cookies.set('fbAccessToken', longLivedToken);

        // Check login state and save token to DB
        checkLoginState();

        // Fetch user profile information
        return fetchFacebookUserProfile(userId, longLivedToken)
          .then(profileData => {
            // Save profile data in cookies and DB
            Cookies.set('fbProfile', profileData.name);
            localStorage.setItem('FbProfile',profileData.name)
            handleSaveFbTokenDb(longLivedToken, profileData.name);
            return { userId, longLivedToken, profileData };
          });
      } else {
        throw new Error('Failed to exchange token');
      }
    });
  })
  .then(({ userId, longLivedToken, profileData }) => {
    console.log("--------------access token profile data:", longLivedToken);
    console.log('User profile data:', profileData);
  })
  .catch(error => {
    console.error('Error during FB login or fetching profile:', error);
  });
};

const fetchFacebookUserProfile = (userId, accessToken) => {
  return new Promise((resolve, reject) => {
    window.FB.api(
      `/${userId}`,
      'GET',
      { fields: 'id,name', access_token: accessToken },
      function(response) {
        if (response && !response.error) {
          resolve(response);
        } else {
          reject(response.error);
        }
      }
    );
  });
};


const postToPage = (message, imageUrl,s3Key) => {
    console.log(pageId, pageAccessToken, message, imageUrl);

    Swal.fire({
      title: "Are you sure?",
      text: "You want to Post",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {
        if (imageUrl !== '') {
          console.log('image one')
                // Step 1: Upload the image to Facebook
                window.FB.api(
                  `/${pageId}/photos`,
                  'POST',
                  {
                    url: imageUrl,
                    access_token: pageAccessToken
                  },
                  function(response) {
                    if (response && !response.error) {
                      const photoId = response.id;
                      console.log('Photo ID: ' + photoId);
            
                      // Step 2: Post the photo to the page feed
                      window.FB.api(
                        `/${pageId}/feed`,
                        'POST',
                        {
                          message: message,
                          object_attachment: photoId, // Attach the uploaded photo
                          access_token: pageAccessToken
                        },
                        function(response) {
                          if (response && !response.error) {
                            console.log('Post ID: ' + response.id);
                            savePostId(response.id,pageId)
                            removeFromS3(s3Key)
                            toast.success('Posted To Facebook Successfully')
                            Swal.fire({
                              title: "Successful",
                              text: "Post Shared Successfully.",
                              icon: "success",
                              showCancelButton: false,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: "Yes"
                            }).then((result) => {
                              if (result.isConfirmed) {
                                window.location.reload();
                              }
                      
                            });
                            setLoadingAnime(false)
                          } else {
                            console.error('Error posting to page:', response.error);
                            removeFromS3(s3Key)
                            toast.error(response.error.message);
                            setLoadingAnime(false)
          
                          }
                        }
                      );
                    } else {
                      console.error('Error uploading photo:', response.error);
                      removeFromS3(s3Key)
                      toast.error(response.error.message);
                      setLoadingAnime(false)
          
                    }
                  }
                );
              } else {
                  console.log('post one')
                // Only post message if there is no image
                window.FB.api(
                  `/${pageId}/feed`,
                  'POST',
                  {
                    message: message,
                    access_token: pageAccessToken
                  },
                  function(response) {
                    if (response && !response.error) {
                      console.log('Post ID: ' + response.id);
                      savePostId(response.id,pageId)
                      toast.success('Posted To Facebook Successfully')
                      Swal.fire({
                        title: "Successful",
                        text: "Post Shared Successfully.",
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes"
                      }).then((result) => {
                        if (result.isConfirmed) {
                          window.location.reload();
                        }
                
                      });
                      setLoadingAnime(false)
          
                    } else {
                      console.error('Error posting to page:', response.error);
                      toast.error(response.error.message);
                      setLoadingAnime(false)
          
                    }
                  }
                );
              }}
        else {
          setLoadingAnime(false)
          return
        }
    })

  

  };

      const handlePostShare= async()=>{
        setLoadingAnime(true)
        console.log('handl post to share')
        const image=''
        if(selectedFile === null){
            const image = ''
            const s3Key = ''
        postToPage(postText,image,s3Key)

        }else{
            const imageData = await uploadImageToServer(selectedFile);
            const image = imageData.imageUrl
            const s3Key = imageData.s3Key
        postToPage(postText,image,s3Key)

        }
        console.log('handlePostShare',postText,image)
      }
          // //////////////////////////////////////////////// LOAD FB SDK
  
          useEffect(() => {
            const loadFbSdk = () => {
              if (!window.FB) {
                window.fbAsyncInit = function() {
                  window.FB.init({
                    appId: FB_APP_ID, // Ensure this is your correct app ID
                    cookie: true,
                    xfbml: true,
                    version: 'v20.0'
                  });
        
                  window.FB.AppEvents.logPageView();
        
                  // Render the login button
                  window.FB.XFBML.parse();
                  setSdkLoaded(true); // SDK has loaded
                };
        
                (function(d, s, id){
                  var js, fjs = d.getElementsByTagName(s)[0];
                  if (d.getElementById(id)) {return;}
                  js = d.createElement(s); js.id = id;
                  js.src = "https://connect.facebook.net/en_US/sdk.js";
                  fjs.parentNode.insertBefore(js, fjs);
                }(document, 'script', 'facebook-jssdk'));
              } else {
                window.FB.XFBML.parse();
                setSdkLoaded(true); // SDK is already loaded
              }
            };
        
            loadFbSdk();
          }, []);
        
          useEffect(() => {
            if (sdkLoaded) {
              if (!Cookies.get('fbAccessToken')) {
                handleFBLogin();
              } else {
                setUserAccessToken(Cookies.get('fbAccessToken'));
        
                getPageAccessTokenAndId(Cookies.get('fbAccessToken'), function(pageId, pageAccessToken) {
                  console.log(`Page ID: ${pageId}`);
                });
              }
            }
          }, [sdkLoaded]);

          const handleImageUpload = (event) => {
            console.log('handle image upload',event.target.files[0])
            const file = event.target.files[0];
            setSelectedFile(file);
            if (file) {
              const imageUrl = URL.createObjectURL(file);
              setImageBlobSrc(imageUrl);
            }
          };

          const uploadImageToServer = async (file) => {
            const formData = new FormData();
            console.log(file,"---file")
            formData.append('image', file);
        
            try {
              const response = await axios.post(`${process.env.REACT_APP_URL}/uploadFacebook`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
              return response.data;
            } catch (error) {
              console.error('Error uploading image:', error);
              throw error;
            }
          };

          const handleDropdownChange = (event) => {
            setDropdownValue(event.target.value);
            // localStorage.setItem('FbProfile',event.target.value)
            const selectedProfile = fbProfileList.find(profile => profile.FbProfile === event.target.value);
        // @
          if (selectedProfile) {
            // Do something with the selected profile
            console.log('Selected Profile:', selectedProfile);
            localStorage.setItem('FbProfile',selectedProfile.FbProfile)
            localStorage.setItem('FbToken',selectedProfile.FbToken)
            Cookies.set('fbAccessToken', selectedProfile.FbToken);
            getPageAccessTokenAndId(selectedProfile.FbToken, function(pageId, pageAccessToken) {
              console.log(`Page ID: ${pageId}`);
            });
        
          } else {
            console.log('Page not found in Page List');
          }
          };

          const handleDropdownChangePage = (event) => {
            setDropdownValuePage(event.target.value);
            // localStorage.setItem('FbProfile',event.target.value)
            const selectedProfile = pageList.find(profile => profile.name === event.target.value);
        
          if (selectedProfile) {
            // Do something with the selected profile
            console.log('Selected Profile:', selectedProfile);
            setPageName(selectedProfile.name)
            setPageId(selectedProfile.id)
            setPageAccessToken(selectedProfile.access_token)
        
          } else {
            console.log('Page not found in Page List');
          }
          };

          const handleFbLogout=()=>{
            console.log('LOgout')
            localStorage.removeItem('FbToken');
  localStorage.removeItem('FbProfile');
  // Remove cookies (assuming you have a function to remove cookies)
  Cookies.remove('fbAccessToken');
  Cookies.remove('fbProfile');
  window.location.reload()
          }
        

          const paperStyle = { padding: 20, minHeight: "90%",width: "50%", margin: "30px",marginTop:'30px' }

  return (
    <div style={{backgroundColor:'whitesmoke'}}>
        {/* FBloginPosting */}
         {/* <button onClick={handleFBLogin}>Log in with Facebook</button> */}
         {/* <button value='Sentence to post on fb' onClick={message=>postToPage(message.target.value)}>Post on Facebook Facebook</button> */}
         <Box sx={{ display: 'flex', minHeight: "100vh", }}>
      <Header />
      <CustomModal
        open={modalOpen}
        handleClose={handleClose}
        title={modalTitle}
        message={modalMessage}
      />

      <Box
      width="95%"
      // border="2px solid purple"
      // bgcolor="#F8F6E3"
      m="5px"
      // p='30px'
      minHeight='87vh'
      marginTop="80px"
      display="flex"
      justifyContent="center"
      alignItems='center'
      flexDirection='column'
      sx={{
        // backgroundImage: 'repeating-radial-gradient(circle at 0 0, rgba(107, 70, 255, .59), #FFF9D0 39%, #03AED2)'
      }}
    >

<Paper
          elevation={1}
          style={{ padding: 10, height: "80px", width: "50%", marginTop: "30px", minWidth:'300px' }}
          sx={{ borderRadius: "10px", bgcolor: "white", display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center' }}
        >
          <Box
            borderRadius="10px"
            bgcolor="#007F73"
            width="70%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={0}
            mx={1}
          >
            <Typography
              fontSize="25px"
              fontWeight="100px"
              color="white"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FacebookIcon sx={{ fontSize: "28px", mr: 2 }} />
              @{localStorage.getItem('FbProfile')}
            </Typography>
          </Box>
          <FormControl 
           borderRadius="10px"
        sx={{ mt: 0, width: '20%', mx: 1 }}
      >
        <InputLabel id="dropdown-label" sx={{ bgcolor: "#007F73", color: "white", pl: 1, pr: 1, borderRadius: 1 }}>Profiles</InputLabel>
        <Select
          labelId="dropdown-label"
          id="dropdown"
          value={dropdownValue}
          onChange={handleDropdownChange}
          label="Profiles"
          sx={{  height:'38px',bgcolor: "#007F73", color: "white", '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
        >
          {
            fbProfileList.map(x =>
              <MenuItem value={x.FbProfile}>{x.FbProfile}</MenuItem>
            )
          }
              <MenuItem onClick={()=>handleFbLogout()} >Log Out</MenuItem>
          
        </Select>
      </FormControl>
        </Paper>

      <Paper
        elevation={1}
        style={{ padding: 2,width: "50%", margin: "5px", minWidth:'300px' }}
        sx={{
          borderRadius: "10px",
          bgcolor: "white",
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          padding: '20px',
          boxSizing: 'border-box',
        }}
      >
        <Box
          borderRadius="10px"
          bgcolor="#007F73"
          width="90%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={3}
        >
         
          <Typography
            fontSize="25px"
            fontWeight="100"
            color="white"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <FacebookIcon sx={{ fontSize: "28px", mr: 2 }} />
            Create Your Post
          </Typography>
</Box>

<Box
          borderRadius="10px"
          bgcolor="#007F73"
          width="90%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={3}
        >
          <Box
            borderRadius="10px"
            bgcolor="#007F73"
            width="70%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={0}
            mx={1}
          >
            <Typography
              fontSize="25px"
              fontWeight="100px"
              color="white"
              sx={{ display: "flex", alignItems: "center" }}
            >
              FB Page - {pageName}
            </Typography>
          </Box>
          <FormControl 
  borderRadius="10px"
  sx={{ mt: 0, width: '20%', mx: 1 }}
>
  <InputLabel id="dropdown-label" sx={{ bgcolor: "#007F73", color: "white", pl: 1, pr: 1, borderRadius: 1 }}>Pages</InputLabel>
  <Select
    labelId="dropdown-label"
    id="dropdown"
    value={dropdownValuePage}
    onChange={handleDropdownChangePage}
    label="Pages"
    style={{color:'white'}}
    sx={{ height: '38px', bgcolor: "#007F73", color: "white", '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' } }}
    MenuProps={{
      PaperProps: {
        sx: {
          bgcolor: "#007F73",
          '& .MuiMenuItem-root': {
            color: 'white',
          },
          '& .MuiMenuItem-root.Mui-selected': {
            bgcolor: '#005F56', // Change to a darker shade or another color for selected item background
            color: 'white',    // Ensures selected item text color is white
          },
        },
      },
    }}
  >
    {
      pageList.map(x =>
        <MenuItem key={x.name} value={x.name} sx={{ color: 'white' }}>
          {x.name}
        </MenuItem>
      )
    }
  </Select>
</FormControl>


        </Box>
    

        {pageId === '' ? (
          <>
            <Button
              sx={{ mt: 2, height: "50px", width: "150px", mb: 10 }}
              color='success'
              variant="contained"
              onClick={handleFBLogin}
              startIcon={<FacebookIcon />}
            >
              Login To Facebook
            </Button>
          </>
        ) : (
          <>
           <TextField
              id="standard-multiline-static"
              label="Write your Facebook Post here"
              multiline
              rows={2}
              variant="standard"
              sx={{ width: "90%", m: "25px" }}
              value={postText}
              onChange={(e) => {
                setPostText(e.target.value);
                setEdited(true);
              }}
            />
            <label htmlFor="upload-image" style={{display:'flex', justifyContent:'end'}}>
              <Button variant="contained" component="span" sx={{ width: '90%', m: '25px', mb:'0px' }}>
                Upload Image
              </Button>
            </label>
            {imageBlobSrc !== '' && (
              <Box sx={{ width: '90%', m: '25px' }}>
                <Typography variant="body1" color="textSecondary">
                  {/* {image.name} */}
                </Typography>
                <img src={imageBlobSrc} alt="Preview" style={{ width: '100%', maxHeight: '250px', objectFit: 'contain' }} />
              </Box>
            )}
            {imageBlobSrc === '' && (
              <Box sx={{ width: '90%', m: '25px' }}>
                <Typography variant="body1" color="textSecondary">
                  {/* {image.name} */}
                </Typography>
                <img src={DummyProfile} alt="Preview" style={{ width: '100%', maxHeight: '250px', objectFit: 'contain' }} />
              </Box>
            )}
            <input
              accept="image/*"
              id="upload-image"
              type="file"
              style={{ display: 'none' }}
              onChange={handleImageUpload}
            />
           
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button
                sx={{ mt: 2, height: "30px", width: "100px", mb: 10 }}
                color='success'
                variant="contained"
                onClick={handlePostShare}
                startIcon={<PostAddIcon />}
              >
                Post
              </Button>
             
             
            </Box>
            {
                loadingAnime?
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '20px' }}>
                <CircularProgress />
              </div>:''
              }
          </>
        )}
      </Paper>
    </Box>

    </Box>
    <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />
    </div>
  )
}

export default FBloginPosting