import { Box, IconButton, Paper, Tooltip, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Navigate, useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2'







export const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const navigate = useNavigate()





  const handleLogout = () => {
    localStorage.clear();
    localStorage.getItem('accessToken')
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes"
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/')
        Swal.fire({
          title: "Sucessfull",
          text: "Log Out Successfully.",
          icon: "success"
        });
      }
    });
    

  }

  const handleError = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Reload the window after clicking "OK"
        window.location.reload();
      }
    });
  }


  const handleDeleteError = () => {
    try {
      const useremail = localStorage.getItem('useremail');
      console.log("For delete account", useremail);
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!"
      }).then((result) => {
        if (result.isConfirmed) {

          fetch(`${process.env.REACT_APP_URL}/deleteuser`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: useremail }),
          })
            .then(response => response.json())
            .then(result => {
              if (result === "True") {
                console.log("User Deleted successfully!!!!!!!!");
                Swal.fire({
                  title: "Deleted!",
                  text: "Your Account has been deleted.",
                  icon: "success"
                });
                localStorage.clear();
                navigate("/");
              } else {
                console.log("Delete user Canceled");
              }
            });
        }
      });
    } catch (error) {
      console.error("Error during account deletion:", error);
      handleError()
      navigate("/mainDashboard");
    }
  }


  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">


      <Box display="flex" justifyContent="flex-end" alignItems="center" >
        <Tooltip title="Delete Account">
          <IconButton onClick={handleDeleteError}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Logout">
          <IconButton onClick={handleLogout}>
            <PersonOutlineOutlinedIcon />
          </IconButton>
        </Tooltip>
      </Box>




    </Box>
  )
}

export default Topbar;
