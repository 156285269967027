import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useEffect, useState } from 'react';

export default function BasicPie() {
  const [twitterCount, setTwitterCount] = useState(0);
  const [linkedinCount, setLinkedinCount] = useState(0);
  const [showMessage, setShowMessage] = useState(false);

  const userNo = () => {
    const email = localStorage.getItem('useremail')
    fetch(`${process.env.REACT_APP_URL}/totalposts`, {
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify({ email : email })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const { twitterPostCount, linkedinPostCount } = data;
        setTwitterCount(twitterPostCount);
        setLinkedinCount(linkedinPostCount);
        console.log(twitterPostCount , linkedinPostCount);
      })
      .catch(error => {
        console.error('Error fetching counts:', error);
      });
  };

  useEffect(() => {
    userNo();
  }, []);

  useEffect(() => {
    if (twitterCount === 0 && linkedinCount === 0) {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  }, [twitterCount, linkedinCount]);

  const data = [
    { id: 0, value: twitterCount, label: 'Twitter Posts' },
    { id: 1, value: linkedinCount, label: 'LinkedIn Posts' },
  ];

  const dataStatic = [
    { id: 0, value: 10, label: 'Twitter Posts' },
    { id: 1, value: 15, label: 'LinkedIn Posts' },
  ];

  const chartData = twitterCount === 0 && linkedinCount === 0 ? dataStatic : data;


  return (
    <div>
      <PieChart
        series={[
          {
            data: chartData,
            highlightScope: { faded: 'global', highlighted: 'item' },
            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
          },
        ]}
        width={500}
        height={200}
      />
      {showMessage && (
        <div style={{ marginTop: '10px', color: 'gray', textAlign: 'center' }}>
          This is static data. Once you share the post, it will be updated.
        </div>
      )}
    </div>
  );
}
