import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const Banner1 = () => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        // height: '200px',
        width: '100%',
        marginTop:'60px',
        paddingTop:'100px',
        paddingBottom:'50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        color: '#252328',
        flexDirection: 'column',
        textAlign: 'center',
        
        boxShadow: 0,
        // borderRadius: 2,
      }}
    >
      <Typography variant="h1" style={{fontSize:isSmallScreen ? '3rem' : '5.8rem', fontWeight:'bolder',marginTop:'50px',paddingTop:'0px',color:'#252328'}} component="div" gutterBottom>
        Welcome to <span style={{color:'#0741AD'}}>Shadow Box</span>
      </Typography>
      <Typography variant="subtitle1" style={{fontSize:'1.5rem', fontWeight:'bold',marginTop:'10px',color:'#252328'}} component="div" sx={{ mb: 2 }}>
      Explore our amazing features and services of <span style={{color:'#0741AD'}}>LinkedIn</span> and <span style={{color:'#0741AD'}}>Twitter</span>
      </Typography>
      <Button
        variant="contained"
        href='/signin'
        sx={{
          backgroundColor: '#0741AD',
          color: 'white',
          fontSize:'1.0rem',
          '&:hover': {
            color: 'black',
            backgroundColor: '#8ac539',
          },
        }}
      >
        Get Started
      </Button>
    </Box>
  );
};

export default Banner1;