import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';

import TwitterIcon from '@mui/icons-material/Twitter';
import { Paper } from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import { BorderAllRounded, Height, Visibility, VisibilityOff } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useEffect } from 'react';
import { LoginSocialFacebook } from 'reactjs-social-login'
import { FacebookLoginButton } from 'react-social-login-buttons'
import favicon from '../../Images/favicon.ico'
import Swal from 'sweetalert2'


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://dshgsonic.com/">
                DSHGSonic
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();
// localStorage.clear();

//Twitter Working URI
//href='https://twitter.com/i/oauth2/authorize?client_id=NmFhMjVZeHNpVlRWd3Fqc3dsTDI6MTpjaQ&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Ftwitterloader&response_type=code&scope=tweet.read+follows.read+mute.read+like.read+block.read+offline.access&state=0-rsJAIDgALlYWs0SDQNIUWwzniGEGFfHy-OpbugHmw%3D&code_challenge=challenge&code_challenge_method=plain '



export const SignIn = () => {


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [signInType, setSignInType] = useState('first');
    const navigate = useNavigate()

    const handleSwitchSignIn = () => {
        setSignInType(signInType === 'first' ? 'second' : 'first');
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };





    const handleFieldError = () => {
        Swal.fire({
            title: "Error",
            text: "Please fill out all fields",
            icon: "warning",
            showCancelButton: false, // Set to false to hide the cancel button
            showConfirmButton: true, // Set to true to show the confirm (OK) button
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK" // Change the text of the confirm button to "OK"
          }).then((result) => {
            if (result.isConfirmed) {
              console.log("email error");
              
            }
          });
    }




    const handleWrongPass = () => {
        Swal.fire({
            title: "Error",
            text: "Wrong Password!!",
            icon: "warning",
            showCancelButton: false, // Set to false to hide the cancel button
            showConfirmButton: true, // Set to true to show the confirm (OK) button
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK" // Change the text of the confirm button to "OK"
          }).then((result) => {
            if (result.isConfirmed) {
              console.log("email error");
              
            }
          });
    }





    const handleWrongEmailError = () => {
        Swal.fire({
            title: "Error",
            text: "Email not found",
            icon: "warning",
            showCancelButton: false, // Set to false to hide the cancel button
            showConfirmButton: true, // Set to true to show the confirm (OK) button
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK" // Change the text of the confirm button to "OK"
          }).then((result) => {
            if (result.isConfirmed) {
              console.log("email error");
              window.location.reload();
            }
          });
    }

    const handleError = () => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        }).then((result) => {
          if (result.isConfirmed) {
            // Reload the window after clicking "OK"
            window.location.reload();
          }
        });
      }

   

    const handleSubmit = async (event) => {
        event.preventDefault();
        localStorage.clear();

        const formData = new FormData(event.currentTarget);
        const userEmail = formData.get('email');
        console.log(userEmail);
        const userPassword = formData.get('password');

        if (!userEmail || !password) {
            handleFieldError()
            return;
        }
console.log({ 'email': userEmail, 'password': password })
        try {
            const response = await fetch(`${process.env.REACT_APP_URL}/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: userEmail, password: password }),
            }).then(response => response.json())
                .then(result => {
                    console.log(result);
                    console.log(password);
                    const userPassword = result.password;
                    const useremail = result.email;
                    console.log("in Login 105 " , useremail);
                    const userName = result.name;
                    const userPicture = result.picture;
                    const userSub = result.sub
                    // const accessToken = result.accessToken
                    const twitterAccessToken = result.twitterAccessToken
                    const twitterAccessTokenSecret = result.twitterAccessTokenSecret
                    const twitterProfile = result.twitterProfile

                    const FbToken = result.FbToken
                    const FbProfile = result.FbProfile
                    const InstaToken = result.InstaToken
                    const InstaProfile = result.InstaProfile

                    const accessToken = result.accessToken;
                    const LinkdnProfile = result.LinkdnProfile;
                    const LinkdnSub = result.LinkdnSub;



                    console.log("from database",twitterAccessToken);
                    console.log(twitterAccessTokenSecret);
                    console.log("Access Token from database:" , accessToken);

                    localStorage.setItem('userSub', userSub)
                    localStorage.setItem('userPhoto', userPicture)
                    localStorage.setItem('useremail', useremail)
                    localStorage.setItem('userName', userName)
                    localStorage.setItem('twitterAccessToken', twitterAccessToken)
                    localStorage.setItem('twitterProfile', twitterProfile)
                    localStorage.setItem('twitterAccessTokenSecret', twitterAccessTokenSecret)
                    localStorage.setItem('accessToken', accessToken)
                    localStorage.setItem('userNameLink', LinkdnProfile)
                    localStorage.setItem('userSubLink', LinkdnSub)
                    localStorage.setItem('FbToken', FbToken)
                    localStorage.setItem('FbProfile', FbProfile)
                    localStorage.setItem('InstaToken', InstaToken)
                    localStorage.setItem('InstaProfile', InstaProfile)






                    if (result === "Invalid Password") {
                        console.log("^^^^^^^^^^passsssssssssss");
                        handleWrongPass()
                    } else if (result === "Inavild Email") {
                        handleWrongEmailError()
                    } else {
                        console.log("Login successfull")
                        navigate("/mainDashboard")
                    }

                });


        } catch (error) {
            console.error("Error during login:", error);
            handleError()
        }
    };


    return (
        <ThemeProvider theme={defaultTheme} >
            <Box sx={{ backgroundImage: 'repeating-radial-gradient(circle at 0 0, rgba(107, 70, 255, .59), #14141f 39%, #14141f)', height: '100%' }}>
                <Container component="main" maxWidth="xs" sx={{ display: 'flex', alignItems: 'center', justifyContent:'center' }}>
                    <CssBaseline />
                    <Paper elevation={10} sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center', p: 5, borderRadius: "25px" }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', borderRadius: "25px 25px 0 0", width: "100%" }}>
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }} src={favicon}>
                               
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Sign In
                            </Typography>
                        </Box>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            {/* <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}> */}
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={(e) => setEmail(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <EmailIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <FormControl required sx={{ mt: 1 }} fullWidth variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}

                                    onChange={handlePasswordChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>

                            <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label="Remember me"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={localStorage.clear()}
                            >
                                Sign In
                            </Button>
                            <Box>
                                <Link href="/forgotpassword" variant="body2">
                                    Forgot Password?
                                </Link>
                                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>

                                <Box width="100%" display='flex' flexDirection='row' alignItems='center'>
                                    <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
                                    Don't have an account? <Link href="/signup" variant="body2">
                                       Sign Up 
                                    </Link>
                                    </Typography>
                                    
                                    </Box>
                                    <Typography variant="body2" sx={{ mt: 1, mb: 1 }}>
                                        {"_______________________OR_________________________"}
                                    </Typography>
                                    {/* <Typography variant="body2" sx={{ mt: 3, mb: 1 }}>
                                        {"Don't have an account? Sign Up"}
                                    </Typography> */}
                                    <Button color='success' sx={{ borderRadius: "25px", m: 1 }} variant="contained" startIcon={<LinkedInIcon />} href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77mwnchaglz2x0&redirect_uri=https://shadowbox.ai/loader&scope=profile%20email%20openid%20w_member_social" onClick={localStorage.clear()}>
                                        Sign Up with LinkedIn
                                    </Button>
                                    {/* <Button color='error' sx={{ borderRadius: "25px" , m:1}} variant="contained" startIcon={<XIcon />}  href='https://twitter.com/i/oauth2/authorize?client_id=NmFhMjVZeHNpVlRWd3Fqc3dsTDI6MTpjaQ&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Ftwitterloader&response_type=code&scope=tweet.read+follows.read+mute.read+users.read+like.read+block.read+tweet.write+offline.access&state=0-rsJAIDgALlYWs0SDQNIUWwzniGEGFfHy-OpbugHmw%3D&code_challenge=challenge&code_challenge_method=plain' onClick={localStorage.clear()}>
                                            Sign Up with Twitter
                                        </Button> */}
                                    {/* <LoginSocialFacebook
                                        appId="423367293970718"
                                        autoLoad={false}
                                        fields="name,email,picture"
                                        callback={responseFacebook}
                                        onFailure={(err) => console.log(err)}
                                    >
                                        <FacebookLoginButton size='small' style={{ borderRadius: '25px', fontSize: '12px', padding: '6px 12px' }} />
                                    </LoginSocialFacebook> */}

                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Container>
            </Box>
        </ThemeProvider>
    );
}

export default SignIn;
