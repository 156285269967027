
import { Box, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Swal from 'sweetalert2'



export const TwitterLoader = () => {

  const navigate = useNavigate()

  

  useEffect(()=>{
    const useremail = localStorage.getItem('useremail')
    console.log("useremail",useremail)

    if (useremail === null) {
      Swal.fire({
        title: "Login Required",
        text: "You need to log in to Shadowbox to use this functionality.",
        icon: "warning", // "fail" should be "warning" or "error"
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Login"
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = '/signin'; // Correctly set the window location
        }
      });
    }
    
  },[])


  const twitterAccessToken = localStorage.getItem('twitterAccessToken');
  const twitterRefreshToken = localStorage.getItem('twitterRefreshToken');
  const useremail = localStorage.getItem('useremail')

  // const twitterid = "NmFhMjVZeHNpVlRWd3Fqc3dsTDI6MTpjaQ";
  // const twitterSecret = "FDWq6UhLhcboKzJGvtqFyDU6LhsQVA3jW21RsJ3tEs_aayPp9F"
  // const callBackUri = "http%3A%2F%2Flocalhost%3A3000%2Ftwitterloader"
  // const twitterURI = "https://twitter.com/i/oauth2/authorize?response_type=code&client_id=NmFhMjVZeHNpVlRWd3Fqc3dsTDI6MTpjaQ$redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Ftwitterloader&state=state&scope=tweet.read%20users.read%20follows.read%20offline.access&code_challenge=challenge&code_challenge_method=plain";


  const paperStyle = { height: "50%", width: "40%", borderRadius: 20 };

  useEffect(() => {
    twitterTokens()
  }, [])


  const savetwittertokens = () => {

    const twitterAccessToken = localStorage.getItem('twitterAccessToken')
    const twitterAccessTokenSecret = localStorage.getItem('twitterAccessTokenSecret') 
    const twitterProfile = localStorage.getItem('twitterProfile') 

    fetch(`${process.env.REACT_APP_URL}/savetwittertokens`,

      {
        method: "POST",
        body: JSON.stringify({
          useremail: useremail,
          twitterAccessToken: twitterAccessToken,
          twitterAccessTokenSecret: twitterAccessTokenSecret,
          twitterProfile: twitterProfile
        }),
        headers: { "Content-type": "application/json" }
      })
      .then(response => { console.log(response) })
      .catch(error => { console.log(error) })
  }


  const twitterTokens = async() => {
    const oauth_token = new URLSearchParams(window.location.search).get('oauth_token');
      const oauth_verifier = new URLSearchParams(window.location.search).get('oauth_verifier');
      console.log(oauth_token);
      console.log(oauth_verifier);
      if (oauth_token && oauth_verifier) {
        console.log("^^^^^^^^^^^^^^^^^^^^^^^line 91");
        await axios.get(`${process.env.REACT_APP_URL}/twitter/callback?oauth_token=${oauth_token}&oauth_verifier=${oauth_verifier}`)
          .then(response => {
            console.log(response,"----------twitter tokens response")
            const { accessToken, accessTokenSecret, twitterProfile } = response.data;
            // Store accessToken and accessTokenSecret in localStorage or state
            console.log(response.data);
            console.log('Access Token:', accessToken);
            console.log('Access Token Secret:', accessTokenSecret);
            localStorage.setItem('twitterAccessToken', accessToken)
            localStorage.setItem('twitterAccessTokenSecret', accessTokenSecret)
            localStorage.setItem('twitterProfile', twitterProfile)

            savetwittertokens()
            navigate("/sharetweet")
          })
          .catch(error => {
            console.error('Error exchanging tokens:', error);
          });
      }

  }







  return (
    <Paper elevation={10} style={{ width: "80%", height: "80%" }} sx={{ display: "flex", alignItems: "center", justifyContent: "center", m: 10, marginLeft: 20 }}>
      <Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" height="100%" width="100%" bgcolor="#007F73">

        <Box height="80%" width="40" sx={{
          backgroundImage: "url('https://cdn5.vectorstock.com/i/1000x1000/56/34/graphic-cartoon-character-of-loading-vector-46115634.jpg')",
          height: 400,
          width: 500,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",

        }}>

        </Box>
        <Paper elevation={10} style={paperStyle} sx={{ margin: 2, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
          <Box>
            <Typography sx={{ fontSize: "40px", fontWeight: "1000", margin: 1, color: "orange" }}>
              Sorry to
            </Typography>
            <Typography sx={{ fontSize: "40px", fontWeight: "1000", margin: 1, color: "purple" }}>
              make you wait
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "15px", fontWeight: "400", margin: 1, color: "red" }}>
              Redirecting page pease wait
            </Typography>
          </Box>
        </Paper>

      </Box>
    </Paper>
  )
}

export default TwitterLoader;
